import React, { useEffect } from "react";
import NavBar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import { useSelector } from "react-redux";

import ShippingpolicyComponent from "../../components/common/ShippingpolicyComponent";

function ShippingPolicy() {
  const navbarHeight = useSelector((state) => state.navbar.height);
  return (
    <>
      <NavBar fixedColor={true} />
      <div style={{ height: navbarHeight }}></div>

      <div className="vh-100">
        <ShippingpolicyComponent />
      </div>

      <Footer />
    </>
  );
}

export default ShippingPolicy;
