import { useEffect, useState } from "react";
import { Col, Row, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { sendEventDataToTagManager } from "../../hooks/tagManagerHelper";

export default function CheckoutCart({
  existingAddress,
  isShowPayment,
  totalCartValue,
  shippingCharges,
  selectedPayment,
  discount,
  cartItems,
  calculateDiscount,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (cartItems.length > 0) {
      let eventData = {
        currency: "INR",
        value: parseInt(totalCartValue).toFixed(2),
        items: cartItems.map((e) => ({
          item_name: e.name,
          item_id: e.productId,
          price: e.price,
          item_category: e.category,
          item_variant: e.size,
          quantity: e.quantity,
        })),
      };
      sendEventDataToTagManager({
        event: "begin_checkout",
        ecommerce: { ...eventData },
      });
    }
  }, []);

  useEffect(() => {
    calculateDiscount();
  }, [totalCartValue + shippingCharges]);

  const CartItem = ({ image, name, quantity, price, size, instructions }) => {
    return (
      <li
        style={{ border: "none" }}
        class="list-group-item d-flex bg-light  justify-content-between lh-sm"
      >
        <div className="d-flex align-items-center">
          <div style={{ position: "relative" }}>
            <Image
              src={image}
              alt="Descriptive text"
              className="me-3"
              style={{ width: "60px", height: "70px", minWidth: "60px" }}
            />
            <span
              className="badge bg-secondary"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              {quantity}
            </span>
          </div>

          <div className="text-start">
            <h6 className="my-0">{name}</h6>
            {instructions.trim() !== "" && (
              <div>
                <small className="text-body-secondary">{instructions}</small>
                <br></br>
              </div>
            )}

            <small className="text-body-secondary">{size}</small>
          </div>
        </div>
        <span class="text-body-secondary">₹{price}</span>
      </li>
    );
  };
  return (
    <div>
      <ul class="list-group mb-3  " style={{ border: "none" }}>
        {cartItems.map((item) =>
          CartItem({
            name: item.name,
            image: item.image,
            quantity: item.quantity,
            price: item.price,
            instructions: item.instructions || "",
            size: item.size,
          })
        )}
      </ul>

      <Row className="justify-content-between px-3">
        {isShowPayment && (
          <>
            <Col xs={9} lg={9} sm={10} className="text-left">
              <p>
                {selectedPayment == "payOnline" ? "Shipping" : "Shipping + COD"}
              </p>
            </Col>
            <Col
              xs={3}
              lg={3}
              sm={2}
              className="text-right"
              style={{ marginLeft: "0" }}
            >
              <p>
                <strong>₹{shippingCharges}</strong>
              </p>
            </Col>
          </>
        )}

        {discount > 0 && ( // Only display discount row if there's a discount
          <>
            <Col xs={9} lg={9} sm={10} className="text-left">
              <p>Discount</p>
            </Col>
            <Col
              xs={3}
              lg={3}
              sm={2}
              className="text-right"
              style={{ marginLeft: "0" }}
            >
              <p>
                <strong>-₹{discount.toFixed(2)}</strong>
              </p>
            </Col>
          </>
        )}

        <Col xs={9} lg={9} sm={10} className="text-left">
          <p>Total</p>
        </Col>
        <Col
          xs={3}
          lg={3}
          sm={2}
          className="text-right"
          style={{ marginLeft: "0" }}
        >
          <p>
            <strong>
              ₹
              {(
                (isShowPayment
                  ? totalCartValue + shippingCharges
                  : totalCartValue) - parseFloat(discount)
              ).toFixed(2)}
            </strong>
          </p>
        </Col>
      </Row>
    </div>
  );
}
