import React, { useState, useEffect } from "react";
import NavBar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import CategoryPageComponent from "../../components/Category/CategoryPageComponent";

import BannerMessage from "./BannerMessage";

export default function CategoryPage() {
  const [showBanner, setShowBanner] = useState(true);
  function handleShowBanner() {
    setShowBanner(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar fixedColor={true} />

      <div
        className="d-flex flex-column bg-light"
        style={{
          minHeight: "100vh",
          marginTop: "75px",
        }}
      >
        {showBanner && <BannerMessage onClick={handleShowBanner} />}

        <CategoryPageComponent />

        <Footer />
      </div>
    </>
  );
}
