import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithPhoneNumber } from "firebase/auth";
import { auth, RecaptchaVerifier, db } from "../firebase/index";
import {
  doc,
  getDoc,
  updateDoc,
  setDoc,
  getFirestore,
} from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { sendEventDataToTagManager } from "../hooks/tagManagerHelper";
import Swal from "sweetalert2";

export default function AuthComponent() {
  // Here, we're setting up some state variables using React's useState hook.
  const [number, setNumber] = useState(""); // stores the phone number input by the user
  const [otp, setOtp] = useState(""); // stores the otp input by the user
  const [otpSent, setOtpSent] = useState(false); // a boolean flag that indicates whether an OTP has been sent
  const [timer, setTimer] = useState(0); // a countdown timer for when the user can request a new OTP
  const [error, setError] = useState(""); // stores any error messages
  const [confirmationResult, setConfirmationResult] = useState(null); // stores the result of the OTP confirmation
  const navigate = useNavigate(); // a hook from react-router-dom to programmatically navigate to other routes
  const dispatch = useDispatch();

  const totalCartQuantity = useSelector((state) => state.cart.totalQuantity);

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          handleSubmit();
        },
      },
      auth
    );
  }, []);

  useEffect(() => {
    let timer;
    if (error) {
      timer = setTimeout(() => {
        setError("");
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [error]);

  // The handleSubmit function is called when the user submits the form to request an OTP.
  const handleSubmit = async (event) => {
    event.preventDefault(); // prevents the default form submission behaviour

    if (number.length === 10) {
      Swal.fire({
        title: "Sending OTP",
        didOpen: () => {
          Swal.showLoading();
        },
        allowOutsideClick: false,
      });
      // checks if the entered phone number is of 10 digits

      setOtpSent(true); // sets the otpSent flag to true
      setTimer(60); // starts a 60 second countdown

      // The appVerifier object is used by Firebase to conduct a reCAPTCHA check, which helps prevent abuse.
      const appVerifier = new RecaptchaVerifier(
        "recaptcha-container", // the id of the DOM element to which the reCAPTCHA widget will be attached
        {
          size: "invisible", // an invisible reCAPTCHA; it doesn't require user interaction
        },
        auth // the Firebase auth object
      );

      const phoneNumber = "+91" + number; // appends the country code to the phone number
      try {
        // tries to sign in with the phone number and the reCAPTCHA verifier
        const result = await signInWithPhoneNumber(
          auth,
          phoneNumber,
          appVerifier
        );
        setConfirmationResult(result); // stores the confirmation result
        Swal.fire({
          title: "OTP Sent Successfully",
          timer: 1000,
          icon: "success",
        });
      } catch (err) {
        // if there's an error, it's logged to the console and the error message is stored in state
        console.error(err);
        Swal.close();
        setError(err.message);
      }
    } else {
      // if the entered phone number isn't 10 digits, an error message is set
      Swal.close();
      setError("Please enter a 10 digit number");
    }
  };
  const handleVerifyOTP = async (event) => {
    event.preventDefault();
    if (confirmationResult) {
      Swal.fire({
        title: "Verifying OTP",
        didOpen: () => {
          Swal.showLoading();
        },
        allowOutsideClick: false,
      });
      try {
        const result = await confirmationResult.confirm(otp);
        // console.log("Login successful");

        const userRef = doc(db, "users", result.user.phoneNumber);
        const userSnapshot = await getDoc(userRef);

        if (userSnapshot.exists()) {
          await updateDoc(userRef, {
            deviceOS: "web",
            appVersion: 20230519,
            lastLogin: new Date().toISOString(),
          });
        } else {
          await setDoc(userRef, {
            phoneNo: result.user.phoneNumber,
            addressAdded: false,
            deviceOS: "web",
            appVersion: 20230519,
          });
        }

        sendEventDataToTagManager({ event: "login", method: "PhoneAuth" });
        Swal.close();
        if (totalCartQuantity > 0) {
          navigate("/checkout");
        } else {
          navigate("/account");
        }
      } catch (error) {
        console.error("OTP verification error:", error);
        Swal.close();
        setError("Invalid OTP");
      }
    } else {
      Swal.close();
      setError("Invalid OTP");
    }
  };
  const handleChangeNumber = () => {
    setOtpSent(false); // resets the otpSent flag
    setOtp(""); // clears the OTP
    setError(""); // clears any error messages
  };

  // The handleResendOTP function is called when the user wants to resend the OTP.
  const handleResendOTP = () => {
    if (timer === 0) {
      // checks if the timer has reached zero
      handleSubmit({ preventDefault: () => {} }); // resends the OTP
    }
  };

  // This useEffect hook is used to handle the countdown timer.
  useEffect(() => {
    if (timer > 0) {
      // checks if the timer is more than zero
      setTimeout(() => setTimer(timer - 1), 1000); // reduces the timer by 1 every second
    }
  }, [timer]); // the effect is dependent on the timer state variable

  // The component returns a JSX element that displays the UI of the component.

  return (
    <div>
      {" "}
      <div id="recaptcha-container" style={{ display: "none" }}></div>
      <div className="container-fluid my-5">
        <div className="row">
          <div className="p-5 col-lg-6 d-flex justify-content-center align-items-center flex-column">
            <h1 className="fw-bold text-dark">Shilpajivi</h1>
            <p className="text-dark h4">
              Tailoring Your Style, Crafting Your Story
            </p>
          </div>

          <div className="col-lg-6 d-flex justify-content-center align-items-center ">
            <div
              className="container bg-white p-4 rounded shadow  mx-3 mx-lg-5"
              style={{ width: "400px" }}
            >
              {otpSent ? (
                <form onSubmit={handleVerifyOTP}>
                  <h3 className="text-center " style={{ color: "green" }}>
                    Enter OTP
                  </h3>
                  <p>OTP Successfully sent to: {number}</p>
                  <div className="form-floating mb-3">
                    <input
                      type="tel"
                      className="form-control"
                      id="floatingOtp"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      maxLength="6"
                    />
                    <label htmlFor="floatingOtp">OTP</label>
                  </div>
                  <p
                    className="text-success mb-3"
                    style={{ cursor: "pointer" }}
                    onClick={handleChangeNumber}
                  >
                    Change Number
                  </p>
                  <div class="d-grid gap-2">
                    <button type="submit" className="btn btn-success btn-block">
                      Verify OTP
                    </button>
                  </div>
                  {error && <div className="alert alert-danger">{error}</div>}
                  {timer > 0 && (
                    <p className="text-muted">
                      Please wait {timer} seconds to re-send OTP
                    </p>
                  )}
                  {timer === 0 && (
                    <p
                      className="text-success mt-3"
                      style={{ cursor: "pointer" }}
                      onClick={handleResendOTP}
                    >
                      Resend OTP
                    </p>
                  )}
                </form>
              ) : (
                <form onSubmit={handleSubmit}>
                  <h3 className="text-center " style={{ color: "green" }}>
                    Login / Signup
                  </h3>
                  <p>
                    Please enter your mobile number on which you want to receive
                    OTP verification code.
                  </p>
                  <div className="form-floating mb-3">
                    <input
                      type="tel"
                      className="form-control"
                      id="floatingNumber"
                      placeholder="number address or phone number"
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                    />
                    <label htmlFor="floatingnumber">Phone number</label>
                  </div>
                  {error && <div className="alert alert-danger">{error}</div>}
                  <div class="d-grid gap-2">
                    <button type="submit" className="btn btn-success btn-block">
                      SEND OTP
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
