import { Card, Container, Form, Row, Col, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { addOrder, PayNowAndPlaceOrder } from "../../store/orders-slice";

export default function ChoosePayment({
  handlePaymentSelection,
  selectedPayment,
  existingAddress,
  finalAddress,
  fullName,
  couponCode,
  shippingCharges,
  totalCartValue,
  discount,
  cartItems,
}) {
  const disptach = useDispatch();

  const placeOrderHandler = () => {
    Swal.fire({
      title: "Your Order's being processed.",
      didOpen: () => {
        Swal.showLoading();
        if (!existingAddress.addressAdded) {
          Swal.fire({
            icon: "warning",
            title: "Address is required",
            text: "Please enter your address before proceeding.",
          });
          return;
        }

        disptach(
          addOrder({
            payment: "offline",
            cartPrdocts: cartItems,
            status: "processed",
            address: finalAddress,
            amount: totalCartValue + shippingCharges - discount,
            discount: discount,
            coupon: couponCode,
            fullName: fullName,
            shipping: shippingCharges,
          })
        );
      },
      allowOutsideClick: false,
    });
  };

  const makePayment = () => {
    Swal.fire({
      title: "Your Order's being processed.",
      didOpen: () => {
        Swal.showLoading();
        if (!existingAddress.addressAdded) {
          Swal.fire({
            icon: "warning",
            title: "Address is required",
            text: "Please enter your address before proceeding.",
          });
          return;
        }

        disptach(
          PayNowAndPlaceOrder({
            payment: "online",
            cartPrdocts: cartItems,
            status: "processed",
            firstName: fullName.substring(0, 20),
            amount: totalCartValue + shippingCharges - discount,
            discount: discount,
            coupon: couponCode,
            address: finalAddress,
            fullName: fullName,
            shipping: shippingCharges,
          })
        );
      },
      allowOutsideClick: false,
    });
  };
  return (
    <div>
      <Container>
        <Card
          className="bg-light mb-lg-5"
          style={{ boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)" }}
        >
          <Card.Body>
            <h2>Choose your payment method:</h2>
            <Form.Group>
              <Form.Check
                inline
                type="radio"
                label="Pay online"
                name="paymentMethod"
                id="payOnline"
                value="payOnline"
                checked={selectedPayment === "payOnline"}
                onChange={handlePaymentSelection}
              />
              <Form.Check
                inline
                type="radio"
                label="Cash on delivery"
                name="paymentMethod"
                id="cashOnDelivery"
                value="cashOnDelivery"
                checked={selectedPayment === "cashOnDelivery"}
                onChange={handlePaymentSelection}
              />
            </Form.Group>
            <Row className="d-flex mt-3 justify-content-between">
              <Col xs="auto">
                <Button
                  onClick={
                    selectedPayment === "payOnline"
                      ? makePayment
                      : placeOrderHandler
                  }
                  className="mt-3"
                  variant="success"
                  type="submit"
                >
                  {selectedPayment === "payOnline" ? "Pay Now" : "Place Order"}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
