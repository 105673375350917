import {
  getDoc,
  doc,
  getDocs,
  where,
  query,
  collection,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import ProductDetailComponent from "../../components/Products/ProductDetailComponent";
import { db } from "../../firebase/index";
import "./ProductDetails.css";
import { sendEventDataToTagManager } from "../../hooks/tagManagerHelper";

function ProductDetails() {
  const { productName } = useParams();
  const [ProductData, setProductData] = useState([]);

  const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const docRef = doc(db, "items", productName);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // Document found
          setProductData(docSnap.data());
          let fetchedData = docSnap.data();
          const specialPriceNumber = Number(fetchedData.specialPrice);
          let eventData = {
            items: [
              {
                item_name: fetchedData.title,
                item_id: fetchedData.productId,
                price: specialPriceNumber.toFixed(2),
                item_category: fetchedData.category[0],
                // item_variant: fetchedData.size,
                // quantity: 1,
              },
            ],
          };
          sendEventDataToTagManager({
            event: "view_item",
            ecommerce: { ...eventData },
          });
          // sendEventDataToTagManager //view_item
        } else {
          // Document not found
          // console.log("Document not found for product name:", productName);
          setProductData(null); // Set product data to null or handle accordingly
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchDocument();
    window.scrollTo(0, 0);
  }, [productName]);
  return (
    <div
      className=" d-flex flex-column min-vh-100"
      style={{ paddingTop: navbarHeight }}
    >
      <NavBar fixedColor={true} />
      <div className="px-2 px-sm-2 px-md-5 flex-grow-1">
        <ProductDetailComponent ProductData={ProductData} />
      </div>
      <Footer />
    </div>
  );
}

export default ProductDetails;
