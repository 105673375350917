import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore"; // assuming you're using Firestore
import { db } from "../../firebase/index"; // replace with your firebase import
import { Link } from "react-router-dom";

import BestsellerLoading from "../PlaceHolder/BestsellerLoading";

const TopCollections = ({ gender }) => {
  const [collections, setCollections] = useState([]);
  const [isLoading, setIsLoading] = useState("");
  const [mainDiv, setMainDiv] = useState("d-none");

  useEffect(() => {
    const fetchData = async () => {
      const collectionRef = collection(db, "collection");
      const q = query(
        collectionRef,
        where("gender", "==", gender),
        orderBy("sortId", "asc")
      );
      const snapshot = await getDocs(q);
      const collections = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCollections(collections);
      setIsLoading("d-none");
      setMainDiv(" ");
    };
    fetchData();
  }, [gender]);

  const CollectionItem = ({ collection }) => {
    const { colId, title, collectionImage, collectionName } = collection;

    const imgStyle = {
      position: "relative",
      width: "100%",
      height: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      transition: "opacity 300ms",
      maxWidth: "100%",
      verticalAlign: "middle",
      borderStyle: "none",
      boxSizing: "border-box",
    };

    // const overlayStyle = {
    //   position: "absolute",
    //   top: "0",
    //   left: "0",
    //   width: "100%",
    //   height: "100%",
    //   display: "flex",
    //   flexDirection: "column",

    //   // justifyContent: "center",
    //   // alignItems: "center",
    //   backgroundColor: "rgba(0, 0, 0, 0.2)", // Reduced the alpha value to 0.3
    //   color: "#ffffff",
    //   padding: "10px",
    // };

    const linkStyle = {
      display: "block",
      position: "relative",
      width: "100%",
      height: "100%",
    };

    return (
      <div key={colId} className="item col-sm-12 col-md-6 col-xl-4 six-blocks">
        <div className="sub-banner banner-item">
          <Link
            to={`/collection/${collectionName.replace(/\s+/g, "-")}/${colId}`}
          >
            <div className="img-box mb-4" style={linkStyle}>
              <img src={collectionImage} alt={title} style={imgStyle} />
              {/* <div style={overlayStyle}>
                <div
                  className="btn btn-transparent text-light"
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    minWidth: "130px",
                    maxwidth: "100%",
                    padding: "15px 30px 15px",
                    letterSpacing: "0.05em",
                  }}
                ></div>
              </div> */}
            </div>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <>
      <BestsellerLoading title="Top Collection" isLoading={isLoading} />
      <div className={mainDiv}>
        <div className="container-fluid px-3 my-3">
          <div className="widget-title style_3 ">
            <h3 className="box-title text-center p-2">
              <span className="title " style={{ fontWeight: "400" }}>
                Top Collections
              </span>
            </h3>
          </div>

          <div className="row">
            {collections.length === 0 ? (
              <p className="text-center">Collection is empty.</p>
            ) : (
              collections.map((collection) => (
                <CollectionItem key={collection.id} collection={collection} />
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TopCollections;
