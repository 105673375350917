import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import ImageLoader from "../common/ImageLoader";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

function SwiperGallery({
  currentSlide,
  setCurrentSlide,
  setTotalSlides,
  loadedThumbnails,
  handleImageClick,
}) {
  const thumbnails = loadedThumbnails;

  const SlidesCount = () => (
    <div className="swiper-slides-count text-light">
      <i className="fi-image fs-lg me-2"></i>
      <div className="fs-5 fw-bold ps-1">
        <span>{!currentSlide ? 1 : currentSlide}</span>
        <span>/</span>
        <span>{thumbnails.length}</span>
      </div>
    </div>
  );

  return (
    <LightgalleryProvider>
      <Swiper
        key="swiper key"
        observer={true}
        observeParents={true}
        modules={[Navigation, Pagination]}
        onSlideChange={(swiper) => {
          setCurrentSlide(swiper.realIndex + 1);
        }}
        onInit={(swiper) => {
          setCurrentSlide(swiper.realIndex + 1);
          setTotalSlides(swiper.slides.length - 2);
        }}
        pagination={{
          el: ".swiper-thumbnails",
          clickable: true,
          renderBullet: (index, className) => {
            return `<li class='swiper-thumbnail ${className}'>
                  <img src='${thumbnails[index]}' alt='Thumbnail'>
                </li>`;
          },
        }}
        navigation
        spaceBetween={12}
        loop
        grabCursor
        className="swiper-nav-onhover rounded-3"
      >
        {loadedThumbnails.map((imgSource, index) => (
          <SwiperSlide className="d-flex" key={index}>
            <LightgalleryItem group="any" src={imgSource}>
              <ImageLoader
                key={index}
                className="rounded-3"
                src={imgSource}
                width="100%"
                alt="Image"
                onClick={(e) => handleImageClick(e, index)}
                style={{ objectFit: "fill", loading: "lazy" }}
              />
            </LightgalleryItem>
          </SwiperSlide>
        ))}
        <SlidesCount key="slides-count" />
      </Swiper>

      <ul className="swiper-thumbnails"></ul>
    </LightgalleryProvider>
  );
}
export default SwiperGallery;
