import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import UserAddress from "../UserAddress";
import CheckoutCart from "./CheckoutCart";
import { fetchAddress } from "../../store/address-slice";
import { auth } from "../../firebase/index";
import ChoosePayment from "./ChoosePayment";
import ApplyCoupon from "./ApplyCoupon";
import axios from "axios";

function CheckoutComponent() {
  const [isShowPayment, setIsShowPayment] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState("payOnline");
  const [couponCode, setCouponCode] = useState("");
  const existingAddress = useSelector((state) => state.address.address);
  const totalCartValue = useSelector((state) => state.cart.totalCartValue);
  const cartItems = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();
  let shippingCharges = selectedPayment === "payOnline" ? 95 : 140;
  const [discount, setDiscount] = useState(0);
  const [message, setMessage] = useState({ type: "", text: "" });

  let currentUser = auth?.currentUser?.phoneNumber;
  // let shippingCharges = selectedPayment === "payOnline" ? 1 : 1.7;

  const finalAddress = {
    firstName: existingAddress["firstName"] || "",
    lastName: existingAddress["lastName"] || "",
    address: existingAddress["address"] || "",
    suiteApt: existingAddress["suiteApt"] || "",
    city: existingAddress["city"] || "",
    state: existingAddress["state"] || "",
    zipCode: existingAddress["zipCode"] || "",
  };
  let fullAddress = `${finalAddress.address}, ${finalAddress.suiteApt}, ${finalAddress.city}, ${finalAddress.state}, ${finalAddress.zipCode}`;
  let fullName = `${finalAddress.firstName} ${finalAddress.lastName}`;

  useEffect(() => {
    // Fetch the address only if it does not exist in the Redux store
    if (!existingAddress || Object.keys(existingAddress).length === 0) {
      dispatch(fetchAddress());
    }
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setDiscount(0);
    setMessage("");
  }, [totalCartValue]);

  // const calculateDiscount = (discountType, discountValue) => {
  //   const totalWithShipping = isShowPayment
  //     ? totalCartValue + shippingCharges
  //     : totalCartValue;
  //   if (totalWithShipping > 5000) {
  //     const discountAmount = totalWithShipping * 0.05; // 5% discount
  //     setDiscount(discountAmount);
  //   } else {
  //     setDiscount(0);
  //   }
  // };
  const handleApplyCoupon = async (couponCode) => {
    console.log(currentUser);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}/validate-coupon`,
        {
          couponCode,
          userId: currentUser,
          totalAmount: totalCartValue, // The total cart value
        }
      );

      const data = response.data;
      if (response.status === 200) {
        // Handle success (e.g., apply discount)
        setDiscount(calculateDiscount(data.discountType, data.discountValue));
        return true; // Coupon is valid
      } else {
        setMessage({ type: "error", text: data.error });
        return false; // Coupon is invalid
      }
    } catch (error) {
      console.error("Error applying coupon:", error);
      setMessage({
        type: "error",
        text: error.response?.data?.error || "Failed to apply coupon.",
      });
      return false; // Coupon is invalid
    }
  };

  const calculateDiscount = (discountType, discountValue) => {
    if (discountType === "percentage") {
      return (totalCartValue * discountValue) / 100;
    } else if (discountType === "fixed") {
      return discountValue.toFixed(2);
    }
    return 0;
  };

  function handleProceedPayment(e) {
    e.preventDefault();
    window.scrollTo(0, 0);
    setIsShowPayment((prevState) => !prevState);
  }

  function handleSelectedPayment(event) {
    setSelectedPayment(event.target.value);
  }

  return (
    <div>
      <h1 className="text-center py-3">Checkout</h1>
      <div className="pb-5 container">
        <div className="row">
          <div className="col-lg-8 col-xs-12  order-2 order-lg-1">
            {!isShowPayment && (
              <UserAddress
                existingAddress={existingAddress}
                showPaymentButton={true}
                proceedPayment={handleProceedPayment}
              />
            )}
            {isShowPayment && (
              <div className="container mb-lg-5">
                <h2>Shipping Address:</h2>
                <p>{`${fullName}, ${fullAddress}`}</p>

                <button
                  onClick={handleProceedPayment}
                  className="mb-2 btn btn-link ps-0 pt-0"
                >
                  Edit address
                </button>
              </div>
            )}
            {isShowPayment && (
              <div className="container">
                {" "}
                <ApplyCoupon
                  onApplyCoupon={handleApplyCoupon}
                  setMessage={setMessage}
                  couponCode={couponCode}
                  setCouponCode={setCouponCode}
                  setDiscount={setDiscount}
                  message={message}
                />
              </div>
            )}

            {isShowPayment && (
              <ChoosePayment
                handlePaymentSelection={handleSelectedPayment}
                selectedPayment={selectedPayment}
                cartItems={cartItems}
                existingAddress={existingAddress}
                finalAddress={fullAddress}
                fullName={fullName}
                discount={discount}
                couponCode={couponCode}
                shippingCharges={shippingCharges}
                totalCartValue={totalCartValue}
              />
            )}
          </div>
          <div className="col-lg-4 bg-muted col-xs-12 order-1 order-lg-2">
            <CheckoutCart
              cartItems={cartItems}
              discount={discount}
              existingAddress={existingAddress}
              isShowPayment={isShowPayment}
              calculateDiscount={calculateDiscount}
              selectedPayment={selectedPayment}
              shippingCharges={shippingCharges}
              totalCartValue={totalCartValue}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutComponent;
