import React from "react";
import classes from "./HomeSliderLoading.module.css";
export default function HomeSliderLoading({ minHeight, isLoading }) {
  return (
    <div
      className={`container-fluid ${isLoading}`}
      style={{ height: minHeight }}
    >
      <div className={`${classes.phpicture} ${classes.loadinganimation}`}></div>
    </div>
  );
}
