import React, { useEffect } from "react";

import NavBar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import { useSelector } from "react-redux";
import ContactUsComponent from "../../components/common/ContactUsComponent";

function ContacUsPage() {
  const navbarHeight = useSelector((state) => state.navbar.height);
  return (
    <>
      <NavBar fixedColor={true} />
      <div style={{ height: navbarHeight }}></div>

      <ContactUsComponent />

      <Footer />
    </>
  );
}

export default ContacUsPage;
