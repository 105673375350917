import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import CategorySlider from "./CategorySlider";
import { db } from "../../firebase/index";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import SliderLoading from "../PlaceHolder/SliderLoading";
import HomeSliderLoading from "../PlaceHolder/HomeSliderLoading";

const CategoryTop = ({ category, windowDimensions }) => {
  const [sliderData, setSliderData] = useState([]);
  const [isLoading, setIsLoading] = useState("");
  const [mainDiv, setMainDiv] = useState("d-none");

  useEffect(() => {
    const fetchData = async () => {
      const pathSlider = collection(db, "topFlyer");
      // use the category in your Firebase query
      const qp = query(
        pathSlider,
        where("gender", "==", category),
        orderBy("sortId", "asc")
      );

      try {
        const querySnapshotSlider = await getDocs(qp);

        const initialSliderData = [];

        querySnapshotSlider.forEach((doc) => {
          initialSliderData.push(doc.data());
        });

        setSliderData(initialSliderData);
        setIsLoading("d-none");
        setMainDiv("");
      } catch (error) {
        // handle the error
        console.error(error);
        return;
      }
    };

    fetchData();
  }, [category]); // add category as a dependency

  return (
    <>
      <HomeSliderLoading isLoading={isLoading} minHeight={"30vh"} />
      <div className={mainDiv}>
        <Container className="p-0 m-0 overflow-hidden" fluid={true}>
          <CategorySlider
            data={sliderData}
            windowDimensions={windowDimensions}
          />
        </Container>
      </div>
    </>
  );
};

export default CategoryTop;
