import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function ModalOrderItems({
  modalShow,
  setModalShow,
  selectedOrder,
  formatedDate,
}) {
  const CartItem = ({ name, quantity, price, size, productId, image }) => {
    return (
      <li
        style={{ border: "none" }}
        className="list-group-item d-flex bg-light  justify-content-between lh-sm"
      >
        <div className="d-flex align-items-center">
          <div style={{ position: "relative" }}>
            <img
              src={image}
              alt="product image"
              className="me-3"
              style={{ width: "110px", height: "191px", minWidth: "60px" }}
            />
          </div>
          <div className="text-start">
            <h6 className="my-0">{name}</h6>
            <small className="text-body-secondary">
              Size:{" " + size} <span> Quantity: {quantity} </span>
            </small>
            <br />
            <Link
              to={`https://shilpajivi.com/items/${productId}`}
              target="_blank"
            >
              {productId}
            </Link>
          </div>
        </div>
        <span class="text-body-secondary">₹{price}</span>
      </li>
    );
  };
  return (
    <div>
      {modalShow ? (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton className="bg-light">
            <Modal.Title id="contained-modal-title-vcenter">
              Id: {selectedOrder?.orderId} Details
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="bg-light">
            {selectedOrder["payment"] === "online" && (
              <p>
                <strong>Transaction id:</strong>{" "}
                {selectedOrder["transaction_id"]}
              </p>
            )}
            <p>
              <strong>Date:</strong>
              {"  "}
              {formatedDate(new Date(selectedOrder?.createdAt))}
            </p>
            <p>
              <strong>Payment:</strong> {selectedOrder["payment"]}
            </p>
            <p>
              <strong>
                {selectedOrder["payment"] === "online"
                  ? "Shipping"
                  : "Shipping + COD"}
                :
              </strong>{" "}
              {"  "}₹{selectedOrder?.shippingCharges}
            </p>
            {selectedOrder?.discount !== 0 && (
              <p>
                <strong>Discount:</strong> ₹{selectedOrder.discount}
              </p>
            )}
            <p>
              <strong>Total Price:</strong> {"  "}₹{selectedOrder?.amount}
            </p>

            <p>
              <strong>Status:</strong> {selectedOrder?.status}
            </p>
            {selectedOrder?.coupon !== "" && (
              <p>
                <strong>Coupon:</strong> {selectedOrder.coupon}
              </p>
            )}
            {selectedOrder?.products.map((product) =>
              CartItem({
                key: product["uniqueId"],
                productId: product["productId"],
                name: product["name"],
                price: product["price"],
                quantity: product["quantity"],
                size: product["size"],
                image:
                  product["image"] ||
                  "https://shilpajivi.com/static/media/logo.0854e646f0ba3e2aa83c.png",
                instructions: product["instructions"],
              })
            )}
            <p>
              <strong>Name:</strong> {selectedOrder["fullName"]}
            </p>
            <p>
              {selectedOrder && selectedOrder.address && (
                <p>
                  <b>Delivery address: </b>
                  {selectedOrder.address}
                </p>
              )}
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="dark text-light"
              onClick={() => setModalShow(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
}
