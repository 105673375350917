import React, { useEffect } from "react";

import NavBar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import AboutUsComponent from "../../components/common/AboutUsComponent";
import "./AboutUs.css";

function AboutPage() {
  return (
    <>
      <NavBar fixedColor={true} />
      <div className=" container mt-5">
        <AboutUsComponent />
      </div>
      <Footer />
    </>
  );
}

export default AboutPage;
