import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";

const ApplyCoupon = ({
  onApplyCoupon,
  setMessage,
  message,
  setDiscount,
  couponCode,
  setCouponCode,
}) => {
  const [showInput, setShowInput] = useState(true);

  const handleChange = (e) => {
    setCouponCode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!couponCode) {
      setMessage({ type: "error", text: "Please enter a coupon code." });
      return;
    }

    try {
      // Call the function passed via props to validate the coupon and wait for the response
      const isValid = await onApplyCoupon(couponCode);

      if (isValid) {
        setMessage({
          type: "success",
          text: `Coupon ${couponCode} applied successfully!`,
        });
        setShowInput(false);
      }
    } catch (error) {
      setShowInput(true);
      setDiscount(0);
      setMessage({ type: "error", text: "Failed to apply coupon." });
    }
  };

  const handleEnterAnotherCoupon = () => {
    setCouponCode(""); // Clear the previous coupon code
    setShowInput(true); // Show the input field again
    setMessage({}); // Clear any previous messages
    setDiscount(0);
  };

  return (
    <div className="apply-coupon">
      {showInput && (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="couponCode" className="mb-3">
            <Form.Label>Have a Coupon?</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="text"
                value={couponCode}
                onChange={handleChange}
                placeholder="Enter coupon code"
                required
              />
              <Button type="submit" variant="warning" className="ms-2">
                Apply
              </Button>
            </div>
          </Form.Group>
        </Form>
      )}

      {message.text && (
        <Alert
          className="mb-0"
          variant={message.type === "success" ? "success" : "danger"}
        >
          {message.text}
        </Alert>
      )}

      {!showInput && message.type === "success" && (
        <Button
          className="ps-0"
          variant="link"
          onClick={handleEnterAnotherCoupon}
        >
          Enter another coupon
        </Button>
      )}
    </div>
  );
};

export default ApplyCoupon;
