import { getDocs, collection, query, where, orderBy } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import ProductCategoryComponent from "../../components/Products/ProductCategoryComponent";
import { db } from "../../firebase/index";
import "./ProductCategoriesList.css";
function ProductCategoreisList({ arrayContainsAttribute }) {
  const { categoryName, categoryId } = useParams();
  const [ProductData, setProductData] = useState([]);

  const [navbarHeight, setNavbarHeight] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const productsQuery = query(
        collection(db, "items"),
        where(arrayContainsAttribute, "array-contains", categoryId),
        orderBy("sortId", "asc")
      );
      const querySnapshot = await getDocs(productsQuery);

      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        productId: doc.id,
      }));

      setProductData(newData);
    };

    const height = document.querySelector(".navbar").offsetHeight;
    setNavbarHeight(height);

    fetchData();
  }, [categoryId]);

  return (
    <div
      className=" d-flex flex-column min-vh-100"
      style={{ paddingTop: navbarHeight }}
    >
      <NavBar fixedColor={true} />
      <div className="px-2 px-sm-2 px-md-5 flex-grow-1">
        <ProductCategoryComponent
          ProductData={ProductData}
          CategoryId={categoryId}
          CategoryName={categoryName.replace(/-/g, " ")}
        />
      </div>
      <Footer />
    </div>
  );
}

export default ProductCategoreisList;
