import React, { useState, useEffect, Fragment, useRef } from "react";
import { Container, Card, Row } from "react-bootstrap";
import { db } from "../../firebase";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import CategoryLoading from "../PlaceHolder/CategoryLoading";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import SelectedCategories from "./SelectedCategories";
import CartegoryImageNTitle from "./CartegoryImageNTitle";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <FaChevronRight
      // className={className}
      size={38}
      style={{
        ...style,
        position: "absolute",
        display: "block",
        color: "black",
        right: "15px",
        top: "35%",
        zIndex: 2,

        padding: "10px",
        borderRadius: "50%",
      }}
      onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "green")}
      onMouseOut={(e) =>
        (e.currentTarget.style.backgroundColor = "rgba(255,255,255,0.5)")
      }
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <FaChevronLeft
      size={38}
      color="black"
      // className={className}
      style={{
        ...style,
        position: "absolute",
        display: "block",
        color: "black",
        left: "15px",
        top: "35%",

        zIndex: 2,

        padding: "10px",
        borderRadius: "50%",
      }}
      onClick={onClick}
      onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "green")}
      onMouseOut={(e) =>
        (e.currentTarget.style.backgroundColor = "rgba(255,255,255,0.5)")
      }
    />
  );
}

const CategoriesRow = ({ category, handleSareesUpdate }) => {
  const [MenuData, setMenuData] = useState([]);
  const [isLoading, setIsLoading] = useState("");
  const [mainDiv, setMainDiv] = useState("d-none");
  const [showSlectedCats, setShowSelectedCats] = useState(false);
  const [selectedCat, setSelectedCat] = useState(null);
  const sliderRef = useRef(); // Create a reference using useRef hook

  useEffect(() => {
    const fetchData = async () => {
      const path = collection(db, "categories");
      const q = query(
        path,
        orderBy("sortId", "asc"),
        where("gender", "==", category) // Use the category in the Firestore query
      );

      const querySnapshot = await getDocs(q).catch((error) => {
        console.error("Error fetching documents: ", error);
      });

      if (querySnapshot) {
        const categories = [];
        querySnapshot.forEach((doc) => {
          categories.push(doc.data());
        });

        setMenuData(categories);
        let sareesSubCats = categories
          .find((sub) => sub.categoryName === "Sarees")
          ["subcategories"].slice(1);

        handleSareesUpdate(sareesSubCats);
        setIsLoading("d-none");
        setMainDiv(" ");
      } else {
        // Handle the case where querySnapshot is undefined or empty
        // console.log("No documents found");
        // You might want to set a default value for setMenuData or handle it differently.
      }
    };

    fetchData();
  }, [handleSareesUpdate]); //add categories

  var settings = {
    dots: false,
    infinite: MenuData.length > 3 ? true : false, // Change 3 to the minimum number of items you want for infinite scroll
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: MenuData.length,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200, // from md to lg
        settings: {
          slidesToShow: 7,
          slidesToScroll: 6,

          arrows: true,
        },
      },
      {
        breakpoint: 992, // from sm to md
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 576, // from xs to sm
        settings: {
          slidesToShow: 5,
          slidesToScroll: 4,
          arrows: true,
        },
      },
    ],
  };

  function handleShowCats(seletedCategory) {
    setSelectedCat(seletedCategory);
    if (selectedCat && seletedCategory.catId === selectedCat.catId) {
      setShowSelectedCats((prevState) => !prevState);
      setSelectedCat(null);
    } else {
      setShowSelectedCats(true);
    }
  }
  // const CategoryList = this.state.MenuData;
  return (
    <Fragment>
      <CategoryLoading isLoading={isLoading} />
      <div className={mainDiv}>
        <Container className="text-center" fluid={true}>
          <Row style={{ position: "relative" }}>
            <Slider ref={sliderRef} {...settings}>
              {" "}
              {/* Use the ref created by useRef */}
              {MenuData.map((cat, i) => {
                return (
                  <div key={i.toString()} className="p-2 mt-2">
                    <div
                      className="text-link"
                      onClick={() => handleShowCats(cat)}
                      // to={`/collection/${newObject.categoryName}/${newObject.catId}`}
                      style={{ textDecoration: "none" }}
                    >
                      <CartegoryImageNTitle
                        containerProps={{ flexDirection: "column" }}
                        name={cat.categoryName}
                        src={cat.categoryImage}
                        imageStyleProps={
                          selectedCat &&
                          selectedCat.catId === cat.catId && {
                            border: "3px solid maroon",
                          }
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </Slider>
          </Row>
        </Container>
      </div>
      {showSlectedCats && (
        <SelectedCategories
          subCategories={selectedCat.subcategories.slice(1)}
        />
      )}
    </Fragment>
  );
};

export default CategoriesRow;
