import { createSlice } from "@reduxjs/toolkit";

const navbarSlice = createSlice({
  name: "navabar",
  initialState: { height: 0 },
  reducers: {
    updateNavbarHeight(state, action) {
      state.height = action.payload;
    },
  },
});

export const navbarActions = navbarSlice.actions;

export default navbarSlice;
