import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Navbar,
  Dropdown,
  Offcanvas,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FaSlidersH, FaTimes } from "react-icons/fa";
import { BiSort } from "react-icons/bi";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

const ProductCategoryComponent = ({
  ProductData,
  CategoryId,
  CategoryName,
}) => {
  const [documentData, setDocumentData] = useState(null);
  const [filteredProductData, setFilteredProductData] = useState([]);
  const [selectedSize, setSelectedSize] = useState("All");
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false); // New state for Offcanvas visibility

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleShow = () => setShow(true); // function to open Offcanvas
  const handleClose = () => setShow(false); // function to close Offcanvas

  const countProductsInSubcategory = (subcategoryId) => {
    return ProductData.filter((product) =>
      product.subCategoryId.includes(subcategoryId)
    ).length;
  };

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const documentRef = doc(db, "categories", CategoryId);
        const documentSnapshot = await getDoc(documentRef);

        if (documentSnapshot.exists()) {
          setDocumentData(documentSnapshot.data());
        } else {
          // console.log("Document not found!" + CategoryId);
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchDocument();
  }, [CategoryId]);

  const handleSubcategoryClick = (subcategoryId) => {
    const filteredData = ProductData.filter((product) =>
      product.subCategoryId.includes(subcategoryId)
    ).filter(
      (product) => selectedSize === "All" || product.size.includes(selectedSize)
    );

    setFilteredProductData(filteredData);
  };

  // New handler for size clicks
  const handleSizeClick = (size) => {
    setSelectedSize(size);
    const filteredData = ProductData.filter(
      (product) => size === "All" || product.size.includes(size)
    );

    setFilteredProductData(filteredData);
  };
  // Function to sort products from low to high
  const sortLowToHigh = () => {
    const sortedData = [...ProductData].sort(
      (a, b) => a.specialPrice - b.specialPrice
    );
    setFilteredProductData(sortedData);
    setOpen(!open);
  };

  // Function to sort products from high to low
  const sortHighToLow = () => {
    const sortedData = [...ProductData].sort(
      (a, b) => b.specialPrice - a.specialPrice
    );
    setFilteredProductData(sortedData);
    setOpen(!open);
  };

  function calculateDiscountPercentage(price, specialPrice) {
    return Math.round(((price - specialPrice) / price) * 100);
  }

  const MyView = (
    filteredProductData.length > 0 ? filteredProductData : ProductData
  ).map((ProductList, i) => {
    return (
      <Col
        className="product-col mb-3"
        xs={6}
        sm={6}
        md={4}
        xl={3}
        key={ProductList.productId}
      >
        <Link className="text-link" to={"/items/" + ProductList.productId}>
          <Card className="image-box card w-100" style={{ border: "none" }}>
            <div className="position-relative">
              <img
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "0px",
                  position: "relative",
                  objectFit: "cover",

                  top: "0",
                  left: "0",
                  backgroundColor: "transparent",
                  maxWidth: "100%",
                  border: "none",
                  verticalAlign: "middle",
                  boxSizing: "border-box",
                }}
                src={
                  windowDimensions.width <= 768
                    ? ProductList.thumbnailImage
                    : ProductList["images"][0]
                }
              />
              {ProductList.remark?.includes("nostock") && (
                <div
                  className="p-2 text-white bg-danger position-absolute top-0 start-0"
                  style={{ zIndex: 10 }}
                >
                  Sold out
                </div>
              )}
            </div>
            <Card.Body
              style={{
                paddingBottom: "0",
                border: "none",
                paddingRight: "0",
                paddingLeft: "0",
                backgroundColor: "transparent",
              }}
            >
              <p
                className="product-name-on-card text-start justify"
                style={{
                  color: "#181818",
                  display: "-webkit-box", //use this to make line-clamp work
                  WebkitLineClamp: 1, // limit text to 1 line
                  WebkitBoxOrient: "vertical",
                  lineHeight: "15px",
                  overflow: "hidden",
                  textOverflow: "ellipsis", //adds '...' at the end when text over

                  marginBottom: "4px",
                  textTransform: "capitalize",
                  fontSize: "13px",
                  lineHeight: "12px",
                  fontWeight: 400,
                  whiteSpace: "normal",
                  textAlign: "justify",
                  maxWidth: "100%", // make sure this is same as image width
                }}
              >
                {ProductList.title}
              </p>

              <p
                className="product-price-on-card text-start"
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  marginBottom: "0",
                  lineHeight: "24px",
                  letterSpacing: "normal",
                  color: "#00593b",
                }}
              >
                &#8377;{ProductList.specialPrice}
                {ProductList.price > 0 && (
                  <>
                    {" "}
                    <span
                      className="old-price"
                      style={{
                        fontSize: "13px",
                        marginRight: "0",
                        // marginLeft: "5px",
                        color: "#21201d",
                        fontWeight: 600,
                        lineHeight: "24px",
                        letterSpacing: "normal",
                      }}
                    >
                      <s>&#8377;{ProductList.price}</s>
                    </span>
                    <span
                      className="percentage-price"
                      style={{
                        color: "#770806",
                        fontSize: "13px",
                        fontWeight: 400,
                      }}
                    >
                      {ProductList.price && ProductList.specialPrice
                        ? `(${calculateDiscountPercentage(
                            ProductList.price,
                            ProductList.specialPrice
                          )}% off)`
                        : ""}
                    </span>
                  </>
                )}
              </p>
            </Card.Body>
          </Card>
        </Link>
      </Col>
    );
  });

  const SideBarFilter = () => {
    const sizes = ["All", "XS", "S", "M", "L", "XL", "XXL"];

    return (
      <div className="text-start ">
        <h4>Subcategories</h4>
        <p
          onClick={() => setFilteredProductData([])}
          style={{ cursor: "pointer" }}
        >
          {CategoryName + " "}({ProductData.length})
        </p>
        {documentData &&
          documentData.subcategories &&
          documentData.subcategories.map(
            (subcat, i) =>
              i !== 0 ? ( // check if it's not the first element
                <p
                  key={i}
                  onClick={() => handleSubcategoryClick(subcat.subcategoryId)}
                  style={{ cursor: "pointer" }}
                >
                  {subcat.subcategoryName} (
                  {countProductsInSubcategory(subcat.subcategoryId)})
                </p>
              ) : null // if it's the first element, do not render anything
          )}

        <h4>Sizes</h4>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginBottom: "5px",
          }}
        >
          {sizes.map((size, i) => {
            if (size === "XL") {
              return (
                <Fragment key={i}>
                  <div className="break-xl"></div>
                  <button
                    type="button"
                    onClick={() => handleSizeClick(size)}
                    className={`btn btn-outline-dark me-2 mb-2 ${
                      selectedSize === size ? "active" : ""
                    }`}
                  >
                    {size}
                  </button>
                </Fragment>
              );
            }

            return (
              <button
                key={i}
                type="button"
                onClick={() => handleSizeClick(size)}
                className={`btn btn-outline-dark me-2 mb-2 ${
                  selectedSize === size ? "active" : ""
                }`}
              >
                {size}
              </button>
            );
          })}
        </div>
        <h4>Prices</h4>
        <button
          type="button"
          className="btn btn-link"
          style={{ paddingLeft: "0", color: "black" }}
          onClick={sortLowToHigh}
        >
          Low to High
        </button>
        <br />
        <button
          type="button"
          className="btn btn-link"
          style={{ paddingLeft: "0", color: "black" }}
          onClick={sortHighToLow}
        >
          High to Low
        </button>
      </div>
    );
  };
  const OffcanvsBarFilter = () => {
    const sizes = ["All", "XS", "S", "M", "L", "XL", "XXL"];

    return (
      <Navbar expand="lg" className="bg-light mb-3">
        <Container fluid className="d-flex justify-content-between">
          <div className="d-flex">
            <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow}>
              <FaSlidersH style={{ color: "grey", fontSize: "28px" }} />
            </Navbar.Toggle>
          </div>
          <div style={{ position: "relative" }}>
            <button
              style={{ color: "grey", fontSize: "28px" }}
              onClick={() => setOpen(!open)}
            >
              <BiSort />
            </button>

            {open && (
              <ul
                style={{
                  position: "absolute",
                  top: "100%",
                  right: 0,
                  backgroundColor: "#fff",
                  border: "1px solid #ccc",
                  listStyle: "none",
                  padding: 0,
                  margin: 0,
                  zIndex: 1000, // Here's the new line
                }}
              >
                <li
                  style={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    minWidth: "max-content",
                  }}
                  onClick={sortLowToHigh}
                >
                  Price: Low to High
                </li>
                <li
                  style={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    minWidth: "max-content",
                  }}
                  onClick={sortHighToLow}
                >
                  Price: High to Low
                </li>
              </ul>
            )}
          </div>

          <Offcanvas
            className="my-offcanvas bg-light"
            show={show}
            onHide={handleClose}
            placement="start"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <Offcanvas.Header closeButton={false}>
              <div></div>
              <FaTimes style={{ color: "black" }} onClick={handleClose} />
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="text-start ">
                <h4>Subcategories</h4>
                <p
                  onClick={() => setFilteredProductData([])}
                  style={{ cursor: "pointer" }}
                >
                  {CategoryName + " "}({ProductData.length})
                </p>
                {documentData &&
                  documentData.subcategories &&
                  documentData.subcategories.map(
                    (subcat, i) =>
                      i !== 0 ? ( // check if it's not the first element
                        <p
                          key={i}
                          onClick={() =>
                            handleSubcategoryClick(subcat.subcategoryId)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {subcat.subcategoryName} (
                          {countProductsInSubcategory(subcat.subcategoryId)})
                        </p>
                      ) : null // if it's the first element, do not render anything
                  )}

                <h4>Sizes</h4>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginBottom: "5px",
                  }}
                >
                  {sizes.map((size, i) => {
                    if (size === "XL") {
                      return (
                        <Fragment key={i}>
                          <div className="break-xl"></div>
                          <button
                            type="button"
                            onClick={() => handleSizeClick(size)}
                            className={`btn btn-outline-dark me-2 mb-2 ${
                              selectedSize === size ? "active" : ""
                            }`}
                          >
                            {size}
                          </button>
                        </Fragment>
                      );
                    }

                    return (
                      <button
                        key={i}
                        type="button"
                        onClick={() => handleSizeClick(size)}
                        className={`btn btn-outline-dark me-2 mb-2 ${
                          selectedSize === size ? "active" : ""
                        }`}
                      >
                        {size}
                      </button>
                    );
                  })}
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </Container>
      </Navbar>
    );
  };
  return (
    <Fragment>
      <h2 className="text-center py-2 " style={{ fontWeight: "400" }}>
        {CategoryName}
      </h2>
      <nav style={{ "--bs-breadcrumb-divider": "'>'" }} aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {CategoryName}
          </li>
        </ol>
      </nav>
      <div className="d-block  d-sm-block d-md-block d-lg-none">
        {OffcanvsBarFilter()}
      </div>
      <Container fluid>
        <Row>
          <Col className="px-0 d-none d-sm-none d-md-none d-lg-block" lg={3}>
            {SideBarFilter()}
          </Col>
          <Col className="px-0" xs={12} sm={12} lg={9}>
            <Row>{MyView}</Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ProductCategoryComponent;
