// usePageTracking.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const sendPageView = () => {
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          page: location.pathname + location.search,
        },
      });
    };

    // Listen to route changes
    const unlisten = () => {
      sendPageView();

      // No need to listen to route changes in React Router v6
    };

    // Trigger the first pageview manually
    sendPageView();

    return unlisten();
  }, [location]);
};

export default usePageTracking;
