import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import giftButton from "../../assets/images/giftbutton.jpg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CartItem from "../Cart/CartItem";

export default function CartOffcanvasComponent({ handleCartClose, show }) {
  //   const [show, setShow] = useState(false);
  const cartItems = useSelector((state) => state.cart.items);
  const totalCartValue = useSelector((state) => state.cart.totalCartValue);
  const navigate = useNavigate();
  const handleCheckoutNavigate = () => {
    handleCartClose();
    navigate("/checkout");
  };
  return (
    <Offcanvas
      style={{ width: "340px" }}
      show={show}
      onHide={handleCartClose}
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Shopping Bag</Offcanvas.Title>
      </Offcanvas.Header>
      <div
        style={{
          boxShadow: "0 -1px   #d0d0d0 inset ,0 1px #d0d0d0 inset",
          padding: " 15px",
          color: "gray",
        }}
      >
        You Qualify For Free Shipping!
      </div>
      <Offcanvas.Body>
        {cartItems.map((item) =>
          CartItem({
            image: item.image,
            productId: item.productId,
            uniqueId: item.uniqueId,
            name: item.name,
            size: item.size,
            price: item.price,
            quantity: item.quantity,
          })
        )}
      </Offcanvas.Body>

      <div
        className="container d-block px-3 px-4"
        style={{ width: "340px", minHeight: "160px", height: "160px" }}
      >
        <div
          className="container d-flex flex-column justify-content-center mt-3"
          style={{
            height: "60px",
            // backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${giftButton})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            color: "white",
            padding: "10px",
          }}
        >
          {/* <div className="d-flex align-items-center">
            <input
              type="checkbox"
              disabled={totalCartValue === 0 && cartItems.length === 0}
              className="me-2 bg-success"
            />
            Gift Wrap For Your Loved Ones?
          </div>
          <small className="mt-1 ms-4">Additional cost of ₹20</small> */}
        </div>

        <button
          onClick={handleCheckoutNavigate}
          disabled={totalCartValue === 0 && cartItems.length === 0}
          type="button"
          className="btn btn-success w-100 p-3 mt-3"
        >
          Checkout{" ₹" + totalCartValue}
        </button>
      </div>
    </Offcanvas>
  );
}
