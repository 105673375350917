import React from "react";

export default function RefundPolicyComponent() {
  return (
    <div className="container-fluid bg-light px-sm-2  px-lg-5 mt-2">
      <h1>Refund Policy</h1>
      <h5>Exchange Policy</h5>
      <p style={{ textAlign: "justify" }}>
        We always try our best to make sure that you love our product as much as
        we loved making it for you.
      </p>
      <p style={{ textAlign: "justify" }}>
        However, we’d be happy to exchange any item that is unworn, unused,
        unwashed, unaltered, undamaged and retaining the original tags, within
        <b>7 days from the date of delivery,</b> for a different size or style.
      </p>
      <h5>Please Note:</h5>
      <p style={{ textAlign: "justify" }}>
        SALE products cannot be exchanged/returned.
      </p>
      <p style={{ textAlign: "justify" }}>
        ACCESSORIES cannot be exchanged/returned.
      </p>
      <h5>Return Policy</h5>
      <p style={{ textAlign: "justify" }}>
        Item that are unworn, unused, unwashed, unaltered, undamaged and
        retaining the original tags are eligible for return within
        <b> 7 days from the date of delivery.</b> All returns happen via Bank
        Transfer which can take upto 15 working days.
      </p>
      <p style={{ textAlign: "justify" }}>
        Incase of a defect in the product, we would require clear parcel opening
        videos or pictures.
      </p>
      <p style={{ textAlign: "justify" }}>
        Reverse pickups are either arranged by us or customer will have to ship
        the parcel to us at the following address:
      </p>
      <p style={{ textAlign: "justify" }}>Name: Bharatiya Shilpajivi</p>
      <p style={{ textAlign: "justify" }}>
        Address: No28, 2nd Floor, Muneswara Layout, Chikka Begur, Bangalore –
        560068
      </p>
      <p style={{ textAlign: "justify" }}>Number: 7090841484</p>
      <p style={{ textAlign: "justify" }}>
        Once we receive it, it will undergo a thorough quality inspection and if
        it passes through, we will process your request.
      </p>
      <p style={{ textAlign: "justify" }}>
        <b>Item is eligible for a return/exchange request only once.</b>
      </p>
      <h5>Cancellation Policy</h5>
      <p style={{ textAlign: "justify" }}>
        No cancellations will be accepted after 24 hours of the order being
        placed.
      </p>
      <h5>Change in Size/Delivery Address/Phone Number</h5>
      <p style={{ textAlign: "justify" }}>
        If you’d like to make changes to existing details in the order such as
        your size, delivery address or phone number, please reach us at
        <b>shilpajivi.india@gmail.com </b>with the subject “Order Number +
        Revised information”. We will facilitate the same if it hasn’t been
        shipped.
      </p>
      <h5>Exchange / Return for International Orders</h5>
      <p style={{ textAlign: "justify" }}>
        No exchange / return is applicable on international orders. For any size
        related queries please feel free to contact us before you place an
        order.
      </p>
      <p style={{ textAlign: "justify" }}>
        <b>
          You can email us on shilpajivi.india@gmail.com for any additional
          queries.
        </b>
      </p>
    </div>
  );
}
