//index.js
import { configureStore } from "@reduxjs/toolkit";
import addressSlice from "./address-slice";
import cartSlice, {
  localStorageMiddleware,
  loadInitialState,
} from "./cart-slice";
import navbarSlice from "./navbar-slice";
import orderSlice from "./orders-slice";
import authSlice from "./auth-slice";
const store = configureStore({
  reducer: {
    address: addressSlice.reducer,
    cart: cartSlice.reducer,
    navbar: navbarSlice.reducer,
    order: orderSlice.reducer,
    auth: authSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(localStorageMiddleware),
});

loadInitialState(store);
export default store;
