import React, { useState, useEffect, Fragment, useRef } from "react";
import { Container, Row, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import BestsellerLoading from "../PlaceHolder/BestsellerLoading";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <FaChevronRight
      size={38}
      style={{
        ...style,
        position: "absolute",
        display: "block",
        color: "black",
        right: "15px",
        top: "35%",
        zIndex: 2,
        padding: "10px",
        borderRadius: "50%",
      }}
      onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "green")}
      onMouseOut={(e) =>
        (e.currentTarget.style.backgroundColor = "rgba(255,255,255,0.5)")
      }
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <FaChevronLeft
      size={38}
      color="black"
      style={{
        ...style,
        position: "absolute",
        display: "block",
        color: "black",
        left: "15px",
        top: "35%",
        zIndex: 2,
        padding: "10px",
        borderRadius: "50%",
      }}
      onClick={onClick}
      onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "green")}
      onMouseOut={(e) =>
        (e.currentTarget.style.backgroundColor = "rgba(255,255,255,0.5)")
      }
    />
  );
}

const ProductSlider = ({
  title,
  queryCondition,
  category,
  windowDimensions,
}) => {
  const [ProductData, setProductData] = useState([]);
  const [isLoading, setIsLoading] = useState("");
  const [mainDiv, setMainDiv] = useState("d-none");
  const sliderRef = useRef();

  useEffect(() => {
    async function fetchData() {
      const path = collection(db, "items");
      const q = query(path, where("gender", "==", category), ...queryCondition);
      const querySnapshot = await getDocs(q).catch((error) => {
        console.log(error);
      });
      // console.log("snap ", querySnapshot);
      const loadedDocs = [];

      querySnapshot.forEach((doc) => {
        loadedDocs.push(doc.data());
      });

      setProductData(loadedDocs);
      setIsLoading("d-none");
      setMainDiv(" ");
    }

    fetchData();
  }, [category, queryCondition]);

  function calculateDiscountPercentage(price, specialPrice) {
    return Math.round(((price - specialPrice) / price) * 100);
  }

  const settings = {
    dots: false,
    infinite: ProductData.length > 4 ? true : false,
    speed: 500,
    slidesToShow: 6, // set to smallest breakpoint value
    slidesToScroll: 6, // set to smallest breakpoint value
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200, // from xl
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 992, // from lg
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 768, // from  md
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 576, // from sm
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 0, // from xs
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <Fragment>
      <BestsellerLoading title={title} isLoading={isLoading} />
      <div className={mainDiv}>
        <Container className="text-center my-3" fluid={true}>
          <div className="section-title text-center mb-3">
            <h2 style={{ fontWeight: 400 }}>{title}</h2>
          </div>

          {ProductData.length > 0 ? (
            <Slider ref={sliderRef} {...settings}>
              {ProductData.map((product, i) => (
                <div key={product.productId.toString()} className="p-1">
                  <Link
                    className="text-link"
                    style={{ textDecoration: "none" }}
                    to={"/items/" + product.productId}
                  >
                    <Card
                      className="image-box card bg-transparent"
                      style={{ border: "none", boxShadow: "none" }}
                    >
                      <div className="position-relative">
                        <img
                          className="center lazyloaded slick-slide slick-slider adaptive_height products-grid product-item"
                          src={
                            windowDimensions.width <= 768
                              ? product.thumbnailImage
                              : product["images"][0]
                          }
                          alt={product.title}
                          style={{
                            objectFit: "cover",
                            width: "100%",
                            height: "auto",
                            position: "relative",
                            top: "0",
                            left: "0",
                            backgroundColor: "transparent",
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block",
                            opacity: "1",
                            transition: "opacity 300ms",
                            border: "none",
                            verticalAlign: "middle",
                            borderStyle: "none",
                            boxSizing: "border-box",
                          }}
                        />
                        {product.remark?.includes("nostock") && (
                          <div
                            className="p-2 text-white bg-danger position-absolute top-0 start-0"
                            style={{ zIndex: 10 }}
                          >
                            Sold out
                          </div>
                        )}
                      </div>
                      <Card.Body
                        style={{
                          border: "none",
                          paddingLeft: "0",
                          backgroundColor: "transparent",
                          paddingBottom: "0",
                        }}
                      >
                        <p
                          className="product-name-on-card text-start justify"
                          style={{
                            color: "#181818",
                            display: "-webkit-box", //use this to make line-clamp work
                            WebkitLineClamp: 1, // limit text to 1 line
                            WebkitBoxOrient: "vertical",
                            lineHeight: "15px",
                            overflow: "hidden",
                            textOverflow: "ellipsis", //adds '...' at the end when text overflows
                            marginBottom: "4px",
                            textTransform: "capitalize",
                            fontSize: "13px",
                            lineHeight: "12px",
                            fontWeight: 400,
                            whiteSpace: "normal",
                            textAlign: "justify",
                            maxWidth: "100%", // make sure this is same as image width
                          }}
                        >
                          {product.title}
                        </p>

                        <p
                          className="product-price-on-card text-start"
                          style={{
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "24px",
                            marginBottom: "0",
                            letterSpacing: "normal",
                            color: "#00593b",
                          }}
                        >
                          &#8377;{product.specialPrice}
                          {product.price > 0 && (
                            <>
                              {" "}
                              <span
                                className="old-price"
                                style={{
                                  fontSize: "13px",
                                  marginRight: "0",
                                  // marginLeft: "5px",
                                  color: "#21201d",
                                  fontWeight: 600,
                                  lineHeight: "24px",
                                  letterSpacing: "normal",
                                }}
                              >
                                <s>&#8377;{product.price}</s>
                              </span>
                              <span
                                className="percentage-price"
                                style={{
                                  color: "#770806",
                                  fontSize: "13px",
                                  fontWeight: 400,
                                }}
                              >
                                {product.price && product.specialPrice
                                  ? `(${calculateDiscountPercentage(
                                      product.price,
                                      product.specialPrice
                                    )}% off)`
                                  : ""}
                              </span>
                            </>
                          )}
                        </p>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>
              ))}
            </Slider>
          ) : (
            <p>{title} Is Empty</p>
          )}
        </Container>
      </div>
    </Fragment>
  );
};

export default ProductSlider;
