import React, { useEffect, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { initializeTagManager } from "./gtm";

import AboutPage from "./pages/AboutUs/AboutUs";
import LoginPage from "./pages/LoginPage/LoginPage";
import AccountPage from "./pages/Account/AccountPage";

import CategoryPage from "./pages/Categories/Categories";
import ProductCategoreisList from "./pages/ProductCategoriesList/ProductCategoriesList";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import CheckoutPage from "./pages/Checkout/CheckoutPage";
import { useAuth } from "./hooks/UseAuth";
import usePageTracking from "./usePageTracking";
import ContacUsPage from "./pages/ContactUs/ContactUs";
import TermsPage from "./pages/Terms/TermsPage";
import PrivacyPage from "./pages/Privacy/PrivacyPage";
import ShippingPolicy from "./pages/ShippingPolicy/ShippingPolicy";
import RefundPlolicy from "./pages/RefundPolicy/RefundPolicy";
import StatusPage from "./pages/Checkout/StatusPage";

function App() {
  useEffect(() => {
    initializeTagManager();
  }, []);
  const isLoggedIn = useAuth();

  // Call usePageTracking hook in the root component
  usePageTracking();
  return (
    <Suspense>
      <div className="App">
        <Routes>
          <Route path="/" element={<CategoryPage />} />
          <Route path="/aboutus" element={<AboutPage />} />
          <Route path="/contactus" element={<ContacUsPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/shippingpolicy" element={<ShippingPolicy />} />
          <Route path="/refundpolicy" element={<RefundPlolicy />} />

          <Route
            path="/category/:categoryName/:categoryId"
            element={<ProductCategoreisList arrayContainsAttribute="mc" />}
          />

          <Route
            path="/collection/:categoryName/:categoryId"
            element={
              <ProductCategoreisList arrayContainsAttribute="collections" />
            }
          />
          <Route path="/status/:transactionId" element={<StatusPage />} />

          <Route path="/checkout" element={<CheckoutPage />} />

          <Route path="/items/:productName" element={<ProductDetails />} />

          <Route path="/login" element={<LoginPage />} />
          {isLoggedIn ? (
            <Route path="/account" element={<AccountPage />} />
          ) : (
            <Route path="*" element={<LoginPage />} />
          )}
        </Routes>
      </div>
    </Suspense>
  );
}

export default App;
