import React from "react";
import SelectedCategories from "./SelectedCategories";

export default function SareesByCraft({ sareesCategories }) {
  return (
    <div className="mt-3  mt-lg-1">
      <div className="section-title text-center mb-3">
        <h2 style={{ fontWeight: 400 }}>Sarees By Craft</h2>
      </div>
      {sareesCategories && (
        <div className="container">
          <SelectedCategories
            backgroundImage={
              "https://firebasestorage.googleapis.com/v0/b/shilpajivi-ec5f9.appspot.com/o/sareesbycraft.webp?alt=media&token=70931d3d-8fe7-4017-9506-1b931e4c1698"
            }
            subCategories={sareesCategories}
          />
        </div>
      )}
    </div>
  );
}
