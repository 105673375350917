import React, { useState, useEffect } from "react";
import { Navbar, Nav, Offcanvas, Container, Button } from "react-bootstrap";
import {
  FaBars,
  FaSearch,
  FaTimes,
  FaRegUser,
  FaChild,
  FaInfoCircle,
  FaEnvelope,
  FaPersonBooth,
  FaChevronRight,
  FaSignOutAlt,
  FaShoppingCart,
  FaUser,
} from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/index";
import { signOut } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux"; // New import
import { addressActions } from "../../store/address-slice";
import CartOffcanvasComponent from "./CartOffcanvasComponent";
import logoImg from "../../assets/images/logo.png";

const CollapsedNavBar = () => {
  const totalCartItemsCount = useSelector((state) => state.cart.totalQuantity);
  const [show, setShow] = useState(false); // New state for Offcanvas visibility
  const [cartShow, setCartShow] = useState(false);

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrolledDown, setScrolledDown] = useState(false);

  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
    setShow(false); // close Offcanvas when a link is clicked
  };

  const dispatch = useDispatch(); // New dispatch function

  const handleShow = () => setShow(true); // function to open Offcanvas
  const handleClose = () => setShow(false); // function to close Offcanvas

  const handleCartShow = () => setCartShow(true);
  const handleCartClose = () => setCartShow(false);

  let currentUser = auth.currentUser;
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.scrollY || document.documentElement.scrollTop;
      if (currentScrollTop > 150) {
        if (currentScrollTop < lastScrollTop) {
          // Scrolling up
          setScrolledDown(false);
        } else {
          // Scrolling down
          setScrolledDown(true);
        }

        setLastScrollTop(currentScrollTop);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);
  const handleLogout = async () => {
    try {
      await signOut(auth);
      dispatch(addressActions.clearAddress()); // Clear address on logout
      navigate("/login"); // redirect user to login page or wherever you want
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  return (
    <Navbar
      className={`${scrolledDown ? "scrolled-down" : "scrolled-up"}`}
      expand="lg"
      bg="black"
      variant="dark"
      fixed="top"
      style={{
        display: scrolledDown ? "none" : "block",
      }} // Hide Navbar when scrolled down
    >
      <Container
        fluid
        className="d-flex px-2 px-sm-2 px-md-5 justify-content-between"
      >
        <div className="d-flex">
          <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow}>
            <FaBars style={{ color: "#E4AE28", fontSize: "28px" }} />
          </Navbar.Toggle>
        </div>

        <LinkContainer on to="/" style={{ color: "light" }}>
          <Navbar.Brand
            className="mx-auto fs-3 fw-bold"
            style={{
              textShadow: "2px 2px 4px #000000",
            }}
          >
            <img
              className="fluid"
              src={logoImg}
              width="150px"
              height="49x"
              alt="shilpajivi-logo"
            />
          </Navbar.Brand>
        </LinkContainer>
        <Button
          onClick={handleCartShow}
          className="bg-transparent"
          style={{ border: "none", position: "relative" }} // Add 'position: relative;' here.
        >
          <Navbar.Brand className="ms-0">
            <FaShoppingCart color="#E4AE28" size={24} />
            {totalCartItemsCount > 0 && (
              <span class="badge bg-success" id="lblCartCount">
                {totalCartItemsCount}
              </span>
            )}
          </Navbar.Brand>
        </Button>
        <CartOffcanvasComponent
          show={cartShow}
          handleCartClose={handleCartClose}
        />

        <Offcanvas
          className="my-offcanvas bg-black"
          show={show}
          onHide={handleClose}
          placement="start"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <Offcanvas.Header closeButton={false}>
            <Offcanvas.Title style={{ color: "#E4AE28" }}>Menu</Offcanvas.Title>
            <FaTimes style={{ color: "#E4AE28" }} onClick={handleClose} />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-column">
              <Nav.Item
                onClick={() =>
                  handleClick("/category/Sarees/IhRSrDqe7OtezxM73vv1")
                }
              >
                <Nav.Link
                  className="d-flex justify-content-between"
                  style={{ color: "#E4AE28" }}
                >
                  <div>
                    <FaPersonBooth style={{ marginRight: "10px" }} /> Sarees
                  </div>
                  <FaChevronRight />
                </Nav.Link>
                <hr
                  style={{
                    margin: "10px 10%",
                    color: "#E4AE28",
                    height: "5px",
                  }}
                />
              </Nav.Item>
              <Nav.Item
                onClick={() =>
                  handleClick("/category/Blouses/FNC8fHWAS2wRujpgcCvJ")
                }
              >
                <Nav.Link
                  className="d-flex justify-content-between"
                  style={{ color: "#E4AE28" }}
                >
                  <div>
                    <FaPersonBooth style={{ marginRight: "10px" }} /> Blouses
                  </div>
                  <FaChevronRight />
                </Nav.Link>
                <hr
                  style={{
                    margin: "10px 10%",
                    color: "#E4AE28",
                    height: "5px",
                  }}
                />
              </Nav.Item>

              <Nav.Item onClick={() => handleClick("/aboutus")}>
                <Nav.Link
                  className="d-flex justify-content-between"
                  style={{ color: "#E4AE28" }}
                >
                  <div>
                    <FaInfoCircle style={{ marginRight: "10px" }} /> About Us
                  </div>
                  <FaChevronRight />
                </Nav.Link>
                <hr
                  style={{
                    margin: "10px 10%",
                    color: "#E4AE28",
                    height: "5px",
                  }}
                />
              </Nav.Item>
              <Nav.Item onClick={() => handleClick("/contactus")}>
                <Nav.Link
                  className="d-flex justify-content-between"
                  style={{ color: "#E4AE28" }}
                >
                  <div>
                    <FaEnvelope style={{ marginRight: "10px" }} /> Contact Us
                  </div>
                  <FaChevronRight />
                </Nav.Link>
                <hr
                  style={{
                    margin: "10px 10%",
                    color: "#E4AE28",
                    height: "5px",
                  }}
                />
              </Nav.Item>
              {currentUser !== null && currentUser.uid !== null ? (
                <Nav.Item onClick={() => handleClick("/account")}>
                  <Nav.Link
                    className="d-flex justify-content-between"
                    style={{ color: "#E4AE28" }}
                  >
                    <div>
                      <FaUser style={{ marginRight: "10px" }} /> Account
                    </div>
                    <FaChevronRight />
                  </Nav.Link>
                  <hr
                    style={{
                      margin: "10px 10%",
                      color: "#E4AE28",
                      height: "5px",
                    }}
                  />
                </Nav.Item>
              ) : (
                <></>
              )}
              {currentUser !== null && currentUser.uid !== null ? (
                <Nav.Item onClick={handleLogout}>
                  <Nav.Link
                    className="d-flex justify-content-between"
                    style={{ color: "#E4AE28" }}
                  >
                    <div>
                      <FaSignOutAlt style={{ marginRight: "10px" }} /> Logout
                    </div>
                    <FaChevronRight />
                  </Nav.Link>
                  <hr
                    style={{
                      margin: "10px 10%",
                      color: "#E4AE28",
                      height: "5px",
                    }}
                  />
                </Nav.Item>
              ) : (
                <Nav.Item onClick={() => handleClick("/login")}>
                  <Nav.Link
                    className="d-flex justify-content-between"
                    style={{ color: "#E4AE28" }}
                  >
                    <div>
                      <FaUser style={{ marginRight: "10px" }} /> Login
                    </div>
                    <FaChevronRight />
                  </Nav.Link>
                  <hr
                    style={{
                      margin: "10px 10%",
                      color: "#E4AE28",
                      height: "5px",
                    }}
                  />
                </Nav.Item>
              )}
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
      </Container>
    </Navbar>
  );
};

export default CollapsedNavBar;
