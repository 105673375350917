import React, { useEffect, useState } from "react";
import { json, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import NavBar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import { useSelector, useDispatch } from "react-redux";
import { sendEventDataToTagManager } from "../../hooks/tagManagerHelper";
import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  getDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import CryptoJS from "crypto-js";
import axios from "axios";

import { cartActions } from "../../store/cart-slice";
function StatusPage() {
  const navbarHeight = useSelector((state) => state.navbar.height);
  const dispatch = useDispatch();
  const { transactionId, transactionStatus } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_END_POINT}/checkorderstatus?id=${transactionId}`
        );

        if (
          response.status === 200 &&
          response.data.data.code === "PAYMENT_SUCCESS"
        ) {
          const encryptedEventData = localStorage.getItem(
            process.env.REACT_APP_PURCHASE_EVENT
          );

          if (encryptedEventData) {
            const bytes = CryptoJS.AES.decrypt(
              encryptedEventData,
              process.env.REACT_APP_MA_MAMA
            );
            let decryptedEventData = JSON.parse(
              bytes.toString(CryptoJS.enc.Utf8)
            );
            decryptedEventData = {
              transaction_id: transactionId,
              ...decryptedEventData,
            };
            sendEventDataToTagManager({
              event: "purchase",
              ecommerce: {
                transaction_id: transactionId,
                ...decryptedEventData,
              },
            });
            localStorage.removeItem(process.env.REACT_APP_PURCHASE_EVENT);
          }
          try {
            const docRef = doc(db, "tempOrders", transactionId);
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
              await deleteDoc(docRef);
              console.log("Document successfully deleted!");
            } else {
              console.log("Document does not exist.");
            }
          } catch (error) {
            // console.error("Error deleting document: ", error);
          }
          dispatch(cartActions.replaceCart());
          Swal.fire({
            icon: "success",
            title: "Transaction Successful!",
            allowOutsideClick: false,
            text: "Your order has been placed.",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/account");
            }
          });
          if (response.data.data.code === "PAYMENT_PENDING") {
            Swal.fire({
              icon: "info",
              title: "Transaction is pending",
              allowOutsideClick: false,
              text: "Your transaction is pending. Please check your orders after 30 minutes. If your order is still not visible after 30 minutes, please call the helpline.",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/account");
              }
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Transaction Failed",
            allowOutsideClick: false,
            text: "Your transaction has failed. Please try again.",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/checkout");
            }
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error occurred while checking order status. Please try again later.",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/checkout");
          }
        });
      } finally {
        setLoading(false); // Set loading to false after the request completes
      }
    };

    fetchData(); // Call fetchData when the component mounts
  }, [transactionId]); // Run effect when 'id' prop changes

  return (
    <div>
      <NavBar fixedColor={true} />
      <div style={{ height: navbarHeight }}></div>
      <div className="bg-light vh-100"></div>

      <Footer />
    </div>
  );
}

export default StatusPage;
