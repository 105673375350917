import { createSlice } from "@reduxjs/toolkit";
import { auth, db } from "../firebase/index";

import { cartActions } from "./cart-slice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { sendEventDataToTagManager } from "../hooks/tagManagerHelper";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import CryptoJS from "crypto-js";

import {
  updateDoc,
  orderBy,
  getDocs,
  addDoc,
  query,
  where,
  collection,
} from "firebase/firestore";

const SECRET_KEY = process.env.REACT_APP_MA_MAMA;

const orderSlice = createSlice({
  name: "order",
  initialState: { orders: [] },
  reducers: {
    replaceOrders(state, action) {
      state.orders = action.payload;
    },
  },
});

const MySwal = withReactContent(Swal);
export const orderActions = orderSlice.actions;

export const addOrder = ({
  cartPrdocts,
  payment,
  status,
  amount,
  address,
  coupon,
  discount,
  shipping,
  fullName,
}) => {
  return async (dispatch) => {
    const path = collection(db, "orders");
    try {
      MySwal.fire({
        title: "Placing your order...",
        didOpen: () => {
          MySwal.showLoading();
        },
        allowOutsideClick: false,
      });

      const docRef = await addDoc(path, {
        payment: payment,
        status: status,
        amount: Number(amount).toFixed(2),
        shippingCharges: shipping,
        discount: Number(discount),
        coupon: coupon,
        createdAt: new Date().toISOString(),
        userId: auth.currentUser.phoneNumber,
        fullName: fullName,
        appVersion: 20240416,
        products: cartPrdocts.map((e) => ({
          uniqueId: e.uniqueId,
          name: e.name,
          size: e.size,
          productId: e.productId,
          quantity: e.quantity,
          price: e.price,
          image: e.image,
          category: e.category,
          instructions: e.instructions,
        })),
        address: address,
      });

      await updateDoc(docRef, { orderId: docRef.id }).then((e) => {
        let eventData = {
          currency: "INR",
          value: amount.toFixed(2),
          tax: 0.0,
          shipping: shipping,
          coupon: coupon,
          discount: discount,
          transaction_id: docRef.id,
          payment: payment,
          items: cartPrdocts.map((e) => ({
            item_name: e.name,
            item_id: e.productId,
            price: e.price,
            item_category: e.category,
            item_variant: e.size,
            quantity: e.quantity,
          })),
        };

        sendEventDataToTagManager({
          event: "purchase",
          ecommerce: { ...eventData },
        });
      });

      dispatch(cartActions.cartUpload(true));

      MySwal.fire({
        title: "Order Successfully Placed!",
        icon: "success",
        confirmButtonText: "Continue Shopping",
        confirmButtonColor: "#008000",
        allowOutsideClick: false,
      });
    } catch (e) {
      console.error("Error adding document: ", e);

      MySwal.fire({
        title: "Something went wrong",
        text: "Unable to place your order",
        icon: "error",
        confirmButtonText: "Try Again",
        allowOutsideClick: true,
      });
    }
  };
};

export const PayNowAndPlaceOrder = ({
  cartPrdocts,
  payment,
  status,
  amount,
  address,
  shipping,
  firstName,
  coupon,
  fullName,
  discount,
}) => {
  return async (dispatch) => {
    MySwal.fire({
      title: "Placing your order...",
      didOpen: () => {
        MySwal.showLoading();
      },
      allowOutsideClick: false,
    });
    const transactionId = "Tr-" + uuidv4().toString(36).slice(-15);
    console.log("online order tranasactionId is: ", transactionId);
    const orderData = {
      payment: payment,
      status: status,
      amount: Number(amount).toFixed(2),
      fullName: fullName,
      discount: Number(discount),
      coupon: coupon,
      shippingCharges: shipping,
      createdAt: new Date().toISOString(),
      merchantTransactionId: transactionId,
      userId: auth.currentUser.phoneNumber,
      appVersion: 20240413,
      products: cartPrdocts.map((e) => ({
        uniqueId: e.uniqueId,
        name: e.name,
        size: e.size,
        productId: e.productId,
        quantity: e.quantity,
        price: e.price,
        image: e.image,
        category: e.category,
        instructions: e.instructions,
      })),
      address: address,
    };

    const requestData = {
      name: firstName,
      phone: auth.currentUser.phoneNumber, // Or any value you want to send
      transactionId: transactionId,
      amount: Number(amount).toFixed(2),
      orderData: orderData,
      MUID: "MUID" + Date.now() + auth.currentUser.phoneNumber.slice(1),
    };
    let eventData = {
      currency: "INR",
      value: Number(amount).toFixed(2),
      tax: 0.0,
      shipping: shipping,
      coupon: coupon,
      discount: discount,
      paymentType: "COD",
      items: cartPrdocts.map((e) => ({
        item_name: e.name,
        item_id: e.productId,
        price: e.price,
        item_category: e.category,
        item_variant: e.size,
        quantity: e.quantity,
      })),
    };
    const encryptedEventData = CryptoJS.AES.encrypt(
      JSON.stringify(eventData),
      SECRET_KEY
    ).toString();

    // Save encrypted eventData to local storage
    localStorage.setItem(
      process.env.REACT_APP_PURCHASE_EVENT,
      encryptedEventData
    );

    try {
      // Send a POST request to the /order route with the data
      const response = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}/order`,
        requestData
      );

      // If the request is successful, get the redirect URL from the response
      const redirectUrl =
        response.data.data.instrumentResponse.redirectInfo.url;

      window.location.href = redirectUrl;
      // window.open(redirectUrl, "_top");
    } catch (error) {
      console.error("Error occurred while making payment:", error);
      Swal.fire({
        icon: "error",
        title: "Payment Failed",
        text: `An error occurred while processing your payment. Please try again later.${error}`,
      });
    }
  };
};

export const fetchOrders = () => {
  return (dispatch) => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const ordersRef = collection(db, "orders");
        const queryOrders = query(
          ordersRef,
          where("userId", "==", user.phoneNumber),
          orderBy("createdAt", "desc") // This line added
        );
        try {
          const querySnapshot = await getDocs(queryOrders);
          let loadedOrders = [];
          let checkOrders = [];

          querySnapshot.forEach((doc) => {
            let data = doc.data();

            let order = {
              orderId: doc.id,
              products: data.products.map((product) => ({
                ...product,
                price: Number(product["price"]),
                quantity: Number(product["quantity"]),
                instructions: product["instructions"] || "",
              })),
              payment: data["payment"],
              fullName: data["fullName"],
              discount: data["discount"] || 0,
              coupon: data["coupon"] || "",
              shippingCharges: data["shippingCharges"],
              amount: Number(data["amount"]),
              transaction_id: data["transaction_id"],
              address: data["address"] || "",
              status: data["status"],
              appVersion: data["appVersion"],
              createdAt: data["createdAt"],
            };
            loadedOrders.push(order);
          });

          // Sort the orders by dateTime in descending order
          // checkOrders = loadedOrders.sort((a, b) => b.createdAt - a.createdAt);

          dispatch(orderActions.replaceOrders(loadedOrders));
        } catch (e) {
          console.error("Error fetching documents: ", e);
        }
      } else {
        // User is signed out
        // console.log("No user is signed in.");
      }
    });
  };
};

export default orderSlice;
