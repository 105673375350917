import React from "react";
import { Card } from "react-bootstrap";
import styles from "./CategoryImageNTitle.module.css";

export default function CartegoryImageNTitle({
  name,
  src,
  containerProps,
  imageStyleProps,
  titleStyleProps,
  imageTextContainer,
  imageStyle,
}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        ...containerProps,
      }}
    >
      <div style={{ ...imageTextContainer }}>
        <Card.Img
          variant="top"
          className={`${styles.categoryCardImage} ${imageStyle}`}
          src={src}
          style={{
            borderRadius: "50%",
            objectFit: "fill",
            ...imageStyleProps,
          }}
        />

        <Card.Title
          style={{
            marginTop: "10px",
            color: "black",
            marginBottom: "0",
            textAlign: "center",
            paddingLeft: "6px",
            paddingRight: "6px",
            textTransform: "capitalize",
            borderRadius: "10px",
            whiteSpace: "normal",
            fontSize: "12px",
            overflow: "hidden",
            textOverflow: "unset",
            ...titleStyleProps,
          }}
        >
          {name}
        </Card.Title>
      </div>
    </div>
  );
}
