import { useState, useEffect } from "react";
import { db } from "../../firebase";
import { getDoc, doc } from "firebase/firestore";

export default function BannerMessage({ onClick }) {
  const [bannerMessage, setbannerMessage] = useState([
    "Shop More | Save More Today",
  ]);

  const [currentMssgIndx, setCurrntMsgIndx] = useState(0);
  useEffect(() => {
    async function fetchMessages() {
      try {
        const docRef = doc(db, "config", "banners"); // Replace "yourDocId" with the ID of the document you want to fetch
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();

          setbannerMessage([...data["messages"]]);
        } else {
          // console.log("No such document exists!");
        }
      } catch (error) {
        // console.log("Error while fetching banners", error);
      }
    }
    fetchMessages();
  }, []);
  useEffect(() => {
    if (bannerMessage.length > 1) {
      const intervalId = setInterval(() => {
        setCurrntMsgIndx((prevIndex) => (prevIndex + 1) % bannerMessage.length);
      }, 2000);

      return () => clearInterval(intervalId);
    }
  }, [bannerMessage]);
  return (
    <section>
      <div
        className="alert mb-0 bg-light"
        role="alert"
        style={{
          textAlign: "center",
          color: "black",
          fontWeight: "bold",
          borderRadius: "0",
          position: "relative", // Adding position relative for absolute positioning of the X mark
        }}
      >
        {bannerMessage[currentMssgIndx]}
        <span
          style={{
            position: "absolute",
            top: "15px", // Adjust top position according to your design
            right: "15px", // Adjust right position according to your design
            cursor: "pointer",
          }}
          onClick={onClick}
        >
          X
        </span>
      </div>
    </section>
  );
}
