import React from "react";
import { Link } from "react-router-dom";
import { FaInstagram, FaFacebook, FaYoutube } from "react-icons/fa";

import "./Footer.css";
import NewsletterForm from "./NewsletterForm";

const Footer = () => {
  return (
    <div className="container-fluid px-5  bg-black">
      <footer className="py-4 mt-3  d mt-0">
        <div className="row" style={{ color: "#E4AE28" }}>
          <div className="col-6 col-lg-2 mb-3 text-nowrap">
            <h5>Shop</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link
                  style={{ color: "#E4AE28" }}
                  to="/category/Sarees/IhRSrDqe7OtezxM73vv1"
                  className="nav-link p-0 "
                >
                  Sarees
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  style={{ color: "#E4AE28" }}
                  to="/category/Blouses/FNC8fHWAS2wRujpgcCvJ"
                  className="nav-link p-0 "
                >
                  Blouses
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-lg-2 mb-3 text-nowrap">
            <h5>About</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link
                  style={{ color: "#E4AE28" }}
                  to="/terms"
                  className="nav-link p-0 "
                >
                  Terms of Service
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  style={{ color: "#E4AE28" }}
                  to="/privacy"
                  className="nav-link p-0 "
                >
                  Privacy Policy
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  style={{ color: "#E4AE28" }}
                  to="/shippingpolicy"
                  className="nav-link p-0 "
                >
                  Shipping Policy
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link
                  style={{ color: "#E4AE28" }}
                  to="/refundpolicy"
                  className="nav-link p-0 "
                >
                  Refund Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-lg-2 mb-3 text-nowrap">
            <h5>Opening Time</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2 ">
                Mon - Fri: 11AM - 8PM
                {/* <Link to="#" className="nav-link p-0 ">
                  Home
                </Link> */}
              </li>
              <li className="nav-item mb-2">
                {/* <Link to="#" className="nav-link p-0 ">
                  Features
                </Link> */}
                Sat - Sun: 10AM - 8PM
              </li>

              {/* <li className="nav-item mb-2">
                <Link 
                style={{color:"#E4AE28"}} to="#" className="nav-link p-0 ">
                  FAQs
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link 
                style={{color:"#E4AE28"}} to="#" className="nav-link p-0 ">
                  About
                </Link>
              </li> */}
            </ul>
          </div>

          {/* ... Repeat for other sections ... */}

          <div className="col-md-6 col-lg-5 offset-lg-1 mb-3">
            <form>
              <h5>Subscribe to our newsletter</h5>
              <p>Monthly digest of what's new and exciting from us.</p>
              <NewsletterForm />
            </form>
          </div>
        </div>

        <div
          className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top"
          style={{ color: "#E4AE28" }}
        >
          <p>
            &copy; {new Date().getFullYear()} Shilpajivi. All rights reserved.
            Developed by{" "}
            <strong>
              <a
                href="https://hybridorganicweb.com"
                className="d-inline-block nav-link-light p-0 blink"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "#E4AE28",
                  textDecoration: "none",
                }}
              >
                HybridOrganicWeb.
              </a>
            </strong>
          </p>

          <ul className="list-unstyled d-flex">
            <li className="ms-3">
              <Link
                style={{ color: "#E4AE28" }}
                className="link-body-emphasis"
                target="_blank"
                to="https://www.youtube.com/channel/UC9bcqJBcsY1yMmxtHKq2AMw"
              >
                <FaYoutube size={24} color="#E4AE28" />
              </Link>
            </li>
            <li className="ms-3">
              <Link
                style={{ color: "#E4AE28" }}
                className="link-body-emphasis"
                target="_blank"
                to="https://www.instagram.com/shilpajivi_bharatiya/"
              >
                <FaInstagram size={24} color="#E4AE28" />
              </Link>
            </li>
            <li className="ms-3">
              <Link
                style={{ color: "#E4AE28" }}
                className="link-body-emphasis"
                target="_blank"
                to="https://www.facebook.com/Shilpajivi"
              >
                <FaFacebook size={24} color="#E4AE28" />
              </Link>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
