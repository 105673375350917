import React, { useState, useEffect } from "react";
import { FaUser, FaShoppingCart, FaSearch, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { auth } from "../../firebase/index";

import { Navbar, Button } from "react-bootstrap";
import CartOffcanvasComponent from "./CartOffcanvasComponent";
import { useSelector } from "react-redux";
import logoImg from "../../assets/images/logo.png";

const UncollapsedNavBar = ({ fixedColor }) => {
  const totalCartItemsCount = useSelector((state) => state.cart.totalQuantity);

  const [cartShow, setCartShow] = useState(false);
  const handleCartShow = () => setCartShow(true);
  const handleCartClose = () => setCartShow(false);

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrolledDown, setScrolledDown] = useState(false);

  let currentUser = auth.currentUser;
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.scrollY || document.documentElement.scrollTop;
      if (currentScrollTop > 150) {
        if (currentScrollTop < lastScrollTop) {
          // Scrolling up
          setScrolledDown(false);
        } else {
          // Scrolling down
          setScrolledDown(true);
        }

        setLastScrollTop(currentScrollTop);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);
  return (
    <Navbar
      className={`${scrolledDown ? "scrolled-down" : "scrolled-up"}`}
      expand="lg"
      bg="black"
      variant="dark"
      fixed="top"
      style={{
        display: scrolledDown ? "none" : "block",
      }} // Hide Navbar when scrolled down
    >
      {/* // <nav
    //   className={
     
    //       ? "navbar navbar-light fixed-top bg-dark navbar-expand-lg py-3 shadow"
    //       : "navbar navbar-light fixed-top bg-transparent navbar-expand-lg py-3 shadow"
    //   }
     
    // > */}
      <div className="container-fluid px-5 d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <Link
            to="/"
            className="navbar-brand fs-3 fw-bold"
            style={{
              textDecoration: "none",
              color: "#E4AE28",
              textShadow: "2px 2px 4px #000000",
            }}
          >
            <img
              className="fluid"
              src={logoImg}
              width="100%"
              height="49px"
              alt="shilpajivi-logo"
            />
          </Link>
        </div>

        <div className="d-flex justify-content-center align-items-center flex-grow-1">
          <ul className="navbar-nav">
            <li className="nav-item mx-2 fw-bold">
              <Link
                to="/category/Sarees/IhRSrDqe7OtezxM73vv1"
                className="nav-link"
                style={{
                  textDecoration: "none",
                  color: "#E4AE28",
                  textShadow: "2px 2px 4px #000000",
                }}
              >
                Sarees
              </Link>
            </li>
            <li className="nav-item mx-2 fw-bold">
              <Link
                to="/category/Blouses/FNC8fHWAS2wRujpgcCvJ"
                className="nav-link"
                style={{
                  textDecoration: "none",
                  color: "#E4AE28",
                  textShadow: "2px 2px 4px #000000",
                }}
              >
                Blouses
              </Link>
            </li>
            {/* <li className="nav-item mx-2 fw-bold">
              <Link
                to="/category/kids"
                className="nav-link"
                style={{
                  textDecoration: "none",
                  color: "#E4AE28",
                  textShadow: "2px 2px 4px #000000",
                }}
              >
                Kids
              </Link>
            </li> */}
            <li className="nav-item mx-2 fw-bold text-nowrap">
              <Link
                to="/aboutus"
                className="nav-link"
                style={{
                  textDecoration: "none",
                  color: "#E4AE28",
                  textShadow: "2px 2px 4px #000000",
                }}
              >
                About Us
              </Link>
            </li>
            <li className="nav-item mx-2 fw-bold text-nowrap">
              <Link
                to="/contactus"
                className="nav-link"
                style={{
                  textDecoration: "none",
                  color: "#E4AE28",
                  textShadow: "2px 2px 4px #000000",
                }}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>

        <div className="d-flex align-items-center">
          {/* <Link
            to="/category/search"
            className="navbar-brand ms-3"
            style={{
              textDecoration: "none",
              color: "#E4AE28",
              textShadow: "2px 2px 4px #000000",
            }}
          >
            <FaSearch size={24} />
          </Link> */}
          <Link
            to={
              currentUser !== null && currentUser.uid !== null
                ? "/account"
                : "/login"
            }
            className="navbar-brand ms-3"
            style={{
              textDecoration: "none",
              color: "#E4AE28",
              textShadow: "2px 2px 4px #000000",
            }}
          >
            <FaUser size={24} />
          </Link>
          <Button
            onClick={handleCartShow}
            className="bg-transparent"
            style={{ border: "none", position: "relative" }} // Add 'position: relative;' here.
          >
            <Navbar.Brand className="ms-3">
              <FaShoppingCart color="#E4AE28" size={24} />
              {totalCartItemsCount > 0 && (
                <span className="badge bg-success" id="lblCartCount">
                  {totalCartItemsCount}
                </span>
              )}
            </Navbar.Brand>
          </Button>

          <CartOffcanvasComponent
            show={cartShow}
            handleCartClose={handleCartClose}
          />

          {/* <Link
            to="/category/cart"
            className="navbar-brand ms-3"
            style={{
              textDecoration: "none",
              color: "#E4AE28",
              textShadow: "2px 2px 4px #000000",
            }}
          >
            <FaShoppingCart size={24} />
          </Link> */}
        </div>
      </div>
    </Navbar>
  );
};

export default UncollapsedNavBar;
