import React from "react";
import pic1 from "../../assets/images/ikat.webp";
import pic2 from "../../assets/images/Kalamkari-Making.webp";

function AboutUsComponent() {
  return (
    <section style={{ paddingTop: "50px" }}>
      <div
        className="container mt-5"
        style={{
          fontFamily:
            "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
        }}
      >
        {" "}
        <h1>
          <b>Our Story</b>
        </h1>
        <h2>Welcome to Shilpajivi!</h2>
        <p className="text-cneter" style={{ textAlign: "justify" }}>
          Your ultimate destination for exquisite Indian handmade clothing that
          celebrates tradition and craftsmanship. At Shilpajivi, we specialize
          in offering a diverse range of handloom sarees, hand-painted sarees,
          Kalamkari silk sarees, and other traditional Indian textiles crafted
          with utmost care and dedication by skilled artisans.
        </p>
        <h2>Why Choose Shilpajivi?</h2>
        <p style={{ textAlign: "justify" }}>
          With a passion for promoting sustainable fashion, we bring you a
          curated collection of ethically sourced and environmentally friendly
          clothing. Our commitment to preserving India's rich textile heritage
          is evident in every piece we showcase, from intricate Kalamkari prints
          to vibrant hand-woven fabrics.
        </p>
        <h2 className="my-3">Celebration in Style</h2>
        <div className="row">
          <div className="col-lg-6">
            <img src={pic1} className="img-fluid" alt="ikat hand craft" />
          </div>
          <div className="col-lg-6">
            <p style={{ textAlign: "justify" }}>
              Indulge in the timeless elegance of our artisanal creations,
              perfect for every occasion, whether it's a festive celebration or
              a special event. Explore our exclusive range of clothing,
              including block-printed sarees, handcrafted dresses, and
              traditional Indian ensembles that embody the essence of Indian
              culture and tradition.
            </p>
            <p style={{ textAlign: "justify" }}>
              Enhance your style effortlessly with our handcrafted accessories,
              tailored to suit any occasion. From beautifully embroidered
              clutches to cozy hand-woven scarves, our collection is all about
              intricate details and timeless charm. Dive into our range of
              jewelry inspired by India's cultural richness, adding a touch of
              tradition to your everyday look. Whether it's a fancy night out or
              a casual hangout, our accessories are the perfect way to elevate
              your outfit with ease. Browse through our selection today and find
              the perfect piece to complete your ensemble with style and grace.
            </p>
          </div>
        </div>
        <h2 className="my-3">Heritage in Every Thread</h2>
        <div className="row">
          <div className="col-lg-6 order-lg-2">
            <img
              src={pic2}
              className="img-fluid"
              alt="heritage in every thread"
            />
          </div>
          <div className="col-lg-6 order-lg-1">
            <p style={{ textAlign: "justify" }}>
              Step into the world of Shilpajivi and immerse yourself in the
              artistry of handcrafted sarees. Each piece is meticulously created
              with traditional hand prints, reflecting the skilled craftsmanship
              and cultural heritage of India. Our sarees are a testament to
              timeless elegance, perfect for every occasion, whether it's a
              festive celebration or a special event. Explore our exclusive
              collection and experience the unparalleled beauty of handcrafted
              sarees that embody the essence of Indian culture and tradition.
            </p>
            <p style={{ textAlign: "justify" }}>
              At Shilpajivi, we take pride in preserving the ancient craft of
              hand printing, ensuring that each saree is a masterpiece of
              intricate design and meticulous attention to detail. Our artisans
              pour their passion and expertise into every piece, resulting in
              sarees that exude a sense of luxury and sophistication. From
              vibrant block prints to delicate motifs, our handcrafted sarees
              are a celebration of artistry and heritage, offering a unique
              expression of style for the discerning woman. Explore our range of
              handcrafted sarees today and adorn yourself in timeless elegance
              that transcends generations.
            </p>
            <p style={{ textAlign: "justify" }}>
              Experience the artistry and charm of Indian handmade clothing with
              Shilpajivi. Shop now and immerse yourself in the beauty of our
              heritage-rich textiles.
            </p>
          </div>
        </div>
        {/* <h2> Fast and Reliable Delivery</h2>
        <div className="row">
          <div className="col-lg-6">
            <img src={pic3} className="img-fluid" alt="Vedam’s Wardrobe" />
          </div>
          <div className="col-lg-6">
            <p style={{ textAlign: "justify" }}>
              At Vedam’s Wardrobe, we recognize the thrill that accompanies the
              arrival of new clothes. There's something special about opening a
              package to reveal a brand-new garment, custom-made just for you.
              That's why we strive to ensure speedy and reliable delivery,
              getting your orders to you as swiftly as possible. Our efficient
              shipping methods and close relationships with trusted delivery
              partners enable us to keep our delivery promise to you, making the
              wait for your new wardrobe additions as short as possible.
            </p>
            <p style={{ textAlign: "justify" }}>
              We also understand that reliability is just as important as speed.
              We know that when you order from us, you're trusting us to deliver
              your custom clothing on time and in perfect condition. That's why
              we make it our mission to fulfill every order with punctuality and
              care. From our factory to your doorstep, we ensure each piece is
              handled with the utmost respect, arriving at its destination ready
              for you to enjoy. At Vedam’s Wardrobe, we promise not just fast
              delivery, but a consistently reliable service that you can trust,
              every time.
            </p>
          </div>
        </div>
        <h2> Made to Measure</h2>
        <div className="row">
          <div className="col-lg-6 order-lg-2">
            <img src={pic4} className="img-fluid" alt="Vedam’s Wardrobe" />
          </div>
          <div className="col-lg-6 order-lg-1">
            <p style={{ textAlign: "justify" }}>
              At Vedam’s Wardrobe, we strongly stand by the philosophy that each
              individual is unique, and their wardrobe should reflect that. As a
              testament to this belief, we offer a made-to-measure service,
              disregarding the concept of a one-size-fits-all approach that is
              common in the fashion industry. Every piece we create is tailored
              to fit you precisely, based on the specific measurements you
              provide. This ensures that not only will your clothing fit
              perfectly, but it will also enhance and flatter your unique body
              shape.
            </p>
            <p style={{ textAlign: "justify" }}>
              Our commitment to a custom-made approach extends beyond just
              measurements. We aim to cater to your distinct style and
              preferences. Whether it's the cut of a shirt, the length of a
              dress, or the fit of a pair of pants, your wishes guide our
              creation process. This made-to-measure ethos allows us to produce
              pieces that not only fit flawlessly but also reflect your personal
              style and character. At Vedam’s Wardrobe, we believe that fashion
              should be a true expression of oneself, and our custom-fit
              clothing is designed to help you express your individuality with
              confidence and flair.
            </p>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default AboutUsComponent;
