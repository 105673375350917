import { useState } from "react";
import { db } from "../../firebase";
import Swal from "sweetalert2";

import { collection, where, query, getDocs, addDoc } from "firebase/firestore";

const NewsletterForm = () => {
  const [email, setEmail] = useState("");
  function handleChange(e) {
    setEmail(e.target.value);
  }
  function isValidEmail(email) {
    // Email regex pattern for basic email validation
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  }
  async function handleSubmit(e) {
    e.preventDefault();

    if (email.trim().length < 5 || !isValidEmail(email.trim())) {
      Swal.fire({
        icon: "error",
        title: "Invalid Email",
        text: "Please enter a valid email address.",
      });
      return;
    }
    Swal.showLoading(); // Show loading spinner
    const emailWS = email.trim();
    const ref = collection(db, "newsletter");
    const q = query(ref, where("email", "==", emailWS));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      // Email doesn't exist, add it
      await addDoc(ref, { email: emailWS });
      setEmail("");
      Swal.close(); // Close loading spinner
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "You have successfully subscribed to our email list.",
      });
    } else {
      // Email already exists
      setEmail("");
      Swal.close(); // Close loading spinner
      Swal.fire({
        icon: "info",
        title: "Info",
        text: "Whoa, it looks like you're already part of the crew! 🚀 We've got your email on file, so no need to subscribe again. Stay tuned for the latest updates!",
      });
    }
  }
  function handleKeyPress(e) {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default behavior
      handleSubmit(e); // Call handleSubmit function
    }
  }
  return (
    <div className="d-flex flex-column flex-sm-row w-100 gap-2">
      <label htmlFor="newsletter1" className="visually-hidden">
        Email address
      </label>
      <input
        style={{
          backgroundColor: "#25211e",
          color: "#a7a7a7",
          borderColor: "#E4AE28",
          paddingTop: "7px",
          paddingBottom: "7px",
          marginRight: "10px",
          marginBottom: "20px",
          padding: "7px 15px 5px",
        }}
        id="newsletter1"
        type="email"
        className="form-control"
        placeholder="Email address"
        value={email}
        onChange={handleChange}
        onKe
        onKeyDown={handleKeyPress}
      />
      <button
        className="btn btn-light"
        type="button"
        onClick={handleSubmit}
        style={{
          backgroundColor: "#5b220e",
          borderColor: "#5b220e",
          padding: "10px 30px",
          textTransform: "uppercase",
          letterSpacing: "0.2em",
          color: "white",
          marginBottom: "15px",
        }}
      >
        Subscribe
      </button>
    </div>
  );
};

export default NewsletterForm;
