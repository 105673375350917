import React, { useState, useCallback, useEffect } from "react";
import CategoryTop from "./CategoryTop";
import CategoriesRow from "./CategoriesRow";
import ProductSlider from "./ProductSlider";
import { where, orderBy } from "firebase/firestore";
import TopCollections from "./TopCollection";
import SareesByCraft from "./SareesByCraft";

export default function CategoryPageComponent() {
  // Get the category from the URL
  // const { category } = useParams();
  const category = "women";
  const [sareesCategories, setSareesCategories] = useState([]);

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSareesUpdate = useCallback((sarees) => {
    setSareesCategories(sarees);
  }, []);

  return (
    <>
      <CategoryTop category={category} windowDimensions={windowDimensions} />
      <CategoriesRow
        category={category}
        handleSareesUpdate={handleSareesUpdate}
      />
      <ProductSlider
        title="New Arrivals"
        category={category}
        windowDimensions={windowDimensions}
        queryCondition={[
          where("remark", "array-contains", "new"),
          orderBy("new", "asc"),
        ]}
      />
      <SareesByCraft sareesCategories={sareesCategories} />

      <div className="my-1">
        <ProductSlider
          title="Best Sellers"
          category={category}
          windowDimensions={windowDimensions}
          queryCondition={[
            where("remark", "array-contains", "bestseller"),
            orderBy("bestseller", "asc"),
          ]}
        />
      </div>

      {/* <YouTubePlayer videoId="4biCsUQQ1Us" /> */}

      <TopCollections gender={category} />
    </>
  );
}
