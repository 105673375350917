import React, { useEffect } from "react";
import NavBar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import { useSelector } from "react-redux";
import RefundPolicyComponent from "../../components/common/RefundPolicyComponent";

function RefundPlolicy() {
  const navbarHeight = useSelector((state) => state.navbar.height);
  return (
    <>
      <NavBar fixedColor={true} />
      <div style={{ height: navbarHeight }}></div>

      <RefundPolicyComponent />

      <Footer />
    </>
  );
}

export default RefundPlolicy;
