import React, { useState, useEffect } from "react";
import { FaCircle } from "react-icons/fa";
import { Card, Row, Col, Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { fetchOrders } from "../store/orders-slice";
import ModalOrderItems from "./ModalOrderItems";

const OrderStatusIcons = ({ status }) => {
  const statusLevels = ["processed", "shipped", "intransit", "delivered"];
  const statusIndex = statusLevels.indexOf(status);

  return (
    <div
      className="order-status-icons-container"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        flexWrap: "nowrap", // changed back to no wrap
      }}
    >
      {statusLevels.map((level, index) => (
        <React.Fragment key={index}>
          <div
            className="order-status-icon"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "0 1px", // further reduced margin
            }}
          >
            <FaCircle
              className="order-status-icon-circle"
              color={statusIndex >= index ? "blue" : "gray"}
            />
            <div
              className="order-status-icon-text"
              style={{ textAlign: "center" }}
            >
              {level}
            </div>
          </div>
          {index < statusLevels.length - 1 && (
            <div
              style={{
                height: "1px",
                backgroundColor: "gray",
                width: "8px", // further reduced width
                alignSelf: "center",
                margin: "0 1px", // further reduced margin
              }}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

const OrderHistoryComponent = () => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const orders = useSelector((state) => state.order.orders);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOrders());
  }, []);
  const formatedDate = (date) => {
    date = new Date(date);
    const formattedDate =
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2) +
      "-" +
      date.getFullYear() +
      " " +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2);

    return formattedDate;
  };
  const CartItem = ({ name, quantity, price, size, instructions }) => {
    return (
      <li
        style={{ border: "none" }}
        className="list-group-item d-flex bg-light p-2 justify-content-between lh-sm"
      >
        <div className="d-flex align-items-center">
          <div style={{ position: "relative" }}>
            {/* <Image
              src={image}
              alt="Descriptive text"
              className="me-3"
              style={{ width: "60px", height: "70px", minWidth: "60px" }}
            /> */}
          </div>
          <div className="text-start">
            <h6 className="my-0">{name}</h6>
            <small className="text-body-secondary">
              Size:{" " + size} <span> Quantity: {quantity} </span>
              {instructions.trim() !== "" && (
                <div>
                  <br></br>
                  {instructions}
                </div>
              )}
            </small>
          </div>
        </div>
        <span class="text-body-secondary"> ₹{price}</span>
      </li>
    );
  };
  return (
    <div
      className="right-column-order"
      id="display-area"
      style={{ overflowY: "auto", padding: "5px" }}
    >
      <h2>Order History</h2>
      {orders.map((order) => (
        <Card
          key={order["orderId"]}
          className="my-5 bg-light"
          style={{
            // backgroundColor: "#F2F1E7",
            boxShadow: "0 10px 8px 0 rgba(0,0,0,0.2)",
            border: "none",
          }}
        >
          <Row>
            <Col xs={12}>
              <Card.Body className="p-2">
                <Card.Title>Id: {"  " + order["orderId"]}</Card.Title>
                <Card.Text>
                  {order["name"]}
                  <br /> ₹{order["amount"]}
                  <br />
                  Status:
                  {order["status"] === "cancelled" ? (
                    "Cancelled"
                  ) : (
                    <OrderStatusIcons status={order.status} />
                  )}
                  <br />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        fontSize: "14px", // Reduced font size for date
                        marginRight: "10px",
                        flexGrow: 1,
                      }}
                    >
                      {formatedDate(order["createdAt"])}
                    </div>
                    <Button
                      variant="dark text-light"
                      style={{
                        fontSize: "12px",
                        whiteSpace: "nowrap",
                        wordSpacing: "-2px",
                      }} // Adjusted button size and text layout
                      onClick={() => {
                        setSelectedOrder(order);
                        setModalShow(true);
                      }}
                    >
                      View Details
                    </Button>
                  </div>
                </Card.Text>
              </Card.Body>
            </Col>
          </Row>
        </Card>
      ))}
      {selectedOrder && (
        <ModalOrderItems
          modalShow={modalShow}
          selectedOrder={selectedOrder}
          setModalShow={setModalShow}
          formatedDate={formatedDate}
        />
      )}
    </div>
  );
};

export default OrderHistoryComponent;
