import React from "react";
import styles from "./CategoryLoading.module.css"; // Import your CSS module

const CategoryLoading = ({ isLoading }) => {
  return (
    <div
      className={`container-fluid d-flex justify-content-around align-items-center ${isLoading}`}
      style={{ height: "150px" }}
    >
      <div className={styles.circularContainer}>
        <div className={`${styles.phpicture} ${styles.loadinganimation}`}></div>
      </div>
      <div className={styles.circularContainer}>
        <div className={`${styles.phpicture} ${styles.loadinganimation}`}></div>
      </div>
      <div className={styles.circularContainer}>
        <div className={`${styles.phpicture} ${styles.loadinganimation}`}></div>
      </div>
      <div className={`d-none d-md-block ${styles.circularContainer}`}>
        <div className={`${styles.phpicture} ${styles.loadinganimation}`}></div>
      </div>
      <div className={`d-none d-lg-block ${styles.circularContainer}`}>
        <div className={`${styles.phpicture} ${styles.loadinganimation}`}></div>
      </div>
      <div className={`d-none d-lg-block ${styles.circularContainer}`}>
        <div className={`${styles.phpicture} ${styles.loadinganimation}`}></div>
      </div>
    </div>
  );
};

export default CategoryLoading;
