import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckoutComponent from "../../components/Checkout/CheckoutComponent";
import NavBar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import { useSelector, useDispatch } from "react-redux";
import { cartActions } from "../../store/cart-slice";

import { useAuth } from "../../hooks/UseAuth";
import Swal from "sweetalert2";

const CheckoutPage = () => {
  const navigate = useNavigate();

  const isLoggedIn = useAuth();
  const totalCartQuantity = useSelector((state) => state.cart.totalQuantity);
  const navbarHeight = useSelector((state) => state.navbar.height);
  const cartUploaded = useSelector((state) => state.cart.cartUploaded);
  const [orderPlaced, setOrderPlaced] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }

    if (orderPlaced) {
      // If the order has been placed, navigate to /account
      Swal.close();
      navigate("/account");
      dispatch(cartActions.replaceCart());
    } else if (cartUploaded) {
      // If the cart has been uploaded but the order hasn't been placed yet, set orderPlaced to true
      setOrderPlaced(true);
    } else if (!cartUploaded && totalCartQuantity < 1) {
      navigate("/");
    }
  }, [
    isLoggedIn,
    totalCartQuantity,
    cartUploaded,
    orderPlaced,
    navigate,
    dispatch,
  ]);
  return (
    <div>
      <NavBar fixedColor={true} />
      <div style={{ height: navbarHeight }}></div>
      <div className="bg-light">
        <CheckoutComponent />
      </div>

      <Footer />
    </div>
  );
};

export default CheckoutPage;
