import OrderHistoryComponent from "./OrderHistoryComponent";

import UserAddress from "./UserAddress";
import React, { useState, useEffect } from "react";
import { auth } from "../firebase";
import {
  FaChevronRight,
  FaAddressCard,
  FaRegListAlt,
  FaSignOutAlt,
  FaUser,
} from "react-icons/fa";
import { Nav } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { fetchAddress } from "../store/address-slice";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const AccountPageComponent = () => {
  // Add a piece of state to keep track of the selected component
  const [selectedComponent, setSelectedComponent] = useState("orderHistory");
  let currentUser = auth.currentUser ? auth.currentUser.phoneNumber : null;
  // Get the existing address from the Redux store
  const existingAddress = useSelector((state) => state.address.address);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the address only if it does not exist in the Redux store
    if (!existingAddress || Object.keys(existingAddress).length === 0) {
      dispatch(fetchAddress());
    }
  }, []);
  // Choose the component to render based on the state
  let ComponentToRender;
  if (selectedComponent === "orderHistory") {
    ComponentToRender = <OrderHistoryComponent />;
  } else if (selectedComponent === "address" && existingAddress) {
    // Add null check here
    ComponentToRender = (
      <UserAddress
        existingAddress={existingAddress}
        showPaymentButton={false}
      />
    );
  }

  const handleLogout = async () => {
    try {
      await signOut(auth);

      navigate("/login"); // redirect user to login page or wherever you want
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };
  return (
    <div className="container pt-5 ">
      <div className="row m-3">
        <div
          className="col-md-4 col-lg-3 bg-dark shadow p-3  rounded text-center text-light  "
          style={{ color: "white", height: "auto" }}
        >
          <div className="py-3">
            <FaUser size={70} style={{ color: "white" }} />{" "}
            <h4 className="mt-2" style={{ color: "white" }}>
              {currentUser}
            </h4>
          </div>

          <Nav className="flex-column">
            <Nav.Item onClick={() => setSelectedComponent("orderHistory")}>
              <Nav.Link
                className="d-flex justify-content-between"
                style={{
                  color:
                    selectedComponent === "orderHistory" ? "yellow" : "white",
                }}
              >
                <div>
                  <FaRegListAlt style={{ marginRight: "10px" }} /> Order History
                </div>
                <FaChevronRight />
              </Nav.Link>
              <hr
                style={{ margin: "10px 10%", color: "white", height: "1px" }}
              />
            </Nav.Item>
            <Nav.Item onClick={() => setSelectedComponent("address")}>
              <Nav.Link
                className="d-flex justify-content-between"
                style={{
                  color: selectedComponent === "address" ? "yellow" : "white",
                }}
              >
                <div>
                  <FaAddressCard style={{ marginRight: "10px" }} /> Address
                </div>
                <FaChevronRight />
              </Nav.Link>
              <hr
                style={{ margin: "10px 10%", color: "white", height: "1px" }}
              />
            </Nav.Item>
            <Nav.Item className="d-none d-lg-block" onClick={handleLogout}>
              <Nav.Link
                className="d-flex justify-content-between"
                style={{
                  color: "white",
                }}
              >
                <div>
                  <FaSignOutAlt style={{ marginRight: "10px" }} /> Logout
                </div>
                <FaChevronRight />
              </Nav.Link>
              <hr
                style={{ margin: "10px 10%", color: "white", height: "1px" }}
              />
            </Nav.Item>
          </Nav>
        </div>

        <div
          className="col-md-8 col-lg-9 right-column-order mt-4 mt-md-0"
          style={{ height: "auto" }}
        >
          {/* Render the selected component */}
          {ComponentToRender}
        </div>
      </div>
    </div>
  );
};

export default AccountPageComponent;
