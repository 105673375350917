import React, { useState, useEffect } from "react";
import NavBar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import AccountPageComponent from "../../components/accountpagecomponent";
import "./AccountPage.css";
import { useSelector, useDispatch } from "react-redux";
import { fetchAddress } from "../../store/address-slice";

export default function AccountPage() {
  const navbarHeight = useSelector((state) => state.navbar.height);
  const existingAddress = useSelector((state) => state.address.address);
  const dispatch = useDispatch();
  useEffect(() => {
    // Fetch the address only if it does not exist in the Redux store
    if (!existingAddress || Object.keys(existingAddress).length === 0) {
      dispatch(fetchAddress());
    }
  }, [dispatch]);
  return (
    <>
      <NavBar fixedColor={true} />
      <div style={{ height: navbarHeight }}></div>
      <div
        className="d-flex flex-column bg-light"
        style={{ minHeight: "100vh" }}
      >
        <div className="flex-grow-1">
          <AccountPageComponent />
        </div>
        <Footer />
      </div>
    </>
  );
}
