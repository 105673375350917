import { Card } from "react-bootstrap";
import CartegoryImageNTitle from "./CartegoryImageNTitle";
import { Link } from "react-router-dom";

export default function SelectedCategories({ subCategories, backgroundImage }) {
  return (
    <Card
      className="mx-2 mx-md-1 bg-light"
      style={{
        minHeight: "100px",
        boxShadow: "2px 9px 23px -4px rgba(0,0,0,0.75)",
      }}
    >
      <div
        style={{
          position: "relative", // Necessary for absolute positioning
        }}
      >
        {/* Background overlay with opacity */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            opacity: 0.2, // Apply opacity only to the overlay
          }}
        />

        {/* Card content container with full opacity */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: "15px",
            marginBottom: "15px",
            position: "relative", // Make it stack on top of overlay
          }}
        >
          {subCategories.map((subcategory, index) => {
            const subName = subcategory.subcategoryName.replace(/\s+/g, "-");
            return (
              <Link
                key={subcategory.subcategoryId}
                to={`/category/${subName}/${subcategory.subcategoryId}`}
                style={{ textDecoration: "none" }}
              >
                <CartegoryImageNTitle
                  name={subcategory.subcategoryName}
                  src={subcategory.image}
                  containerProps={{
                    marginTop: "15px",
                  }}
                  imageTextContainer={{
                    textAlign: "center",
                    alignItem: "center",
                  }}
                  imageStyleProps={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginTop: "5px",
                    border: "3px solid maroon",
                  }}
                  // imageStyle={`${styles.selectedSubCatImage}`}
                />
              </Link>
            );
          })}
        </div>
      </div>
    </Card>
  );
}
