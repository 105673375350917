import React from "react";

export default function PrivacyComponent() {
  return (
    <div className="container-fluid bg-light px-sm-2  px-lg-5 mt-2">
      <h1>Privacy Policy</h1>
      <p style={{ textAlign: "justify" }}>
        At Shilpajivi, your privacy is of utmost importance to us. This Privacy
        Policy outlines the types of personal information we receive and collect
        when you use our services, as well as some of the steps we take to
        safeguard the information. We hope this will help you make an informed
        decision about sharing personal information with us.
      </p>
      <p style={{ textAlign: "justify" }}>
        The domain name www.shilpajivi.com (“Website”) is owned and managed by
        BHARATIYA SHILPAJIVI, a company registered at No:28, Ground Floor,
        Muneswara Layout, 3rd Cross, Chikka Begur, Bangalore -560068, India
        [GSTIN: 29CJPPP3242P1ZB, under the brand name SHILPAJIVI (“BS”).
      </p>
      <p style={{ textAlign: "justify" }}>
        The term “BS”, “Company”, "us", "we" or "our" refers to BHARATIYA
        SHILPAJIVI. The term “User”, “You” or “Your” refers to any user/viewer
        of this Website, his/her representatives or affiliates; and any person
        availing our services through this Website, our Instagram handle
        www.shilpajivi.com, or any other medium. The term “Third Party” refers
        to any person, natural or juristic, other than Shilpajivi and the User.
      </p>
      <p style={{ textAlign: "justify" }}>
        This privacy policy constitutes a complete legally binding contract
        between BS and the User. The terms of Privacy Policy will be applicable
        and effective upon your acceptance of the same and shall govern the
        relationship between the User and BS for the use of this Website.
      </p>
      <p style={{ textAlign: "justify" }}>
        BS is committed to confidentiality and privacy of all its users. All
        identifiable data collected by BS shall be dealt with in accordance with
        this Policy. BS may change this policy from time to time. You should
        check this page from time to time to ensure that you are informed of the
        changes. Your continued use of this website and availing of services of
        BS under this Website implies that you have read, understood and agreed
        to the terms and conditions set forth in this Policy and implies your
        acceptance to any amendments made to this Privacy Policy. Your continued
        use of the website implies your consent to our collection and use of the
        information.
      </p>
      <h5> What information do we collect? </h5>
      <p style={{ textAlign: "justify" }}>
        During the use of this website by you we may collect the following
        categories of information:
        <br></br>1. Name.
        <br></br>2. Contact information including email address, phone number
        and postal address.
        <br></br>3. Demographic information such as gender and age.
        <br></br>4. Any information, including images, uploaded/posted by you on
        our website or on a third-party social media site used by and/or
        associated with BS.
        <br></br>5. Your third-party account credentials (for example, your
        log-in credentials for Facebook, through which you may choose to log-in
        to use this Website).
        <br></br>6. Payment information, as required for the purposes for
        availing services of BS by a User.
        <br></br>7. Measurements appropriate for determining the size of
        clothing.
        <br></br>8. Any other information considered necessary in the sole
        discretion of BS for the performance of services agreed to be procured
        by the User from BS.
      </p>
      <h5>What we use your information for?</h5>
      <p style={{ textAlign: "justify" }}>
        BS may use your information for the following categories of uses:
        <br></br>1. Ascertaining the correct size of the product being ordered:
        We at BS want to ensure that the product you order from us fits you
        perfectly, and to that end we guide the Users to select the correct size
        for their body measurements.
        <br></br>2. Making delivery: The delivery of your orders to the correct
        destination, either by courier or any other service, will require BS
        sharing your contact information with the delivery personnel.
        <br></br>3. Service improvement: Your information may be used by BS to
        improve the standard of its services including by way of innovating new
        products and customisation in your interests.
        <br></br>4. Feedback & Preferences: We may also use your information to
        contact you for feedback and market research purposes. We may contact
        you by email, phone or mail. We may conduct various data analytics
        process using your information to study and examine user preferences in
        order to improve BS’s services. You can opt out of receiving our
        feedback emails.
        <br></br>5. Payment: Your financial information including credit card,
        debit card, bank account or medical insurance related information may be
        used at the payment gateway, for completing payment transactions between
        BS and you.
        <br></br>6. Marketing New Offerings: We may periodically send
        promotional emails/alerts about new products, special offers or other
        information which we think you may find interesting, using the email
        address which you have provided. You can opt out of receiving our
        marketing emails.
        <br></br>7. Advertising: We may use anonymized information so that you
        cannot be individually identified and provide that anonymized
        information to our partners. For example, we allow advertisers to choose
        the demographic information of users who will see their advertisements
        and you agree that we may use any of the information we have collected
        from you in non-personally identifiable form to allow our advertisers to
        select the appropriate audience for those advertisements.
        <br></br>8. Research & Development: We may use your information for
        research and development in the field of retail, including with such
        other entities conducting research on terms materially similar to this
        Policy.
      </p>
      <h5>Security of Your Information</h5>
      <p style={{ textAlign: "justify" }}>
        BS has implemented security protocols and standards to safeguard your
        information as required by the applicable laws of the Republic of India.
        We have in place appropriate data collection, storage and processing
        practices and security measures to protect against unauthorized access,
        alteration, disclosure or destruction of your personal information and
        other data stored on the Website.
      </p>
      <p style={{ textAlign: "justify" }}>
        Notwithstanding anything contained in this Policy, BS shall not be
        responsible, in any manner, for breach of security, or for any actions
        and omissions of the third parties beyond its control including but not
        limited to hacking, data diddling, cyber-attacks, acts of government,
        breach of security and confidentiality, unauthorized access to the
        computer system and network, breach of encryption, amongst others.
      </p>
      <p style={{ textAlign: "justify" }}>
        Your account is protected by a password for your privacy and security.
        If you access your account via Facebook, you may have additional or
        different sign-on protections through Facebook.
      </p>
      <h5>Your Obligation</h5>
      <p style={{ textAlign: "justify" }}>
        You are required to provide true and accurate information to BS for the
        purposes of availing our services and in your own best interests. You
        are solely responsible for any loss or injury occurring due to the
        provision of false, inaccurate or misleading information provided by
        you.
      </p>
      <p style={{ textAlign: "justify" }}>
        BS reserves the right to deny services to you in case you fail to
        provide information considered by BS, in its sole discretion, to be
        necessary for the provision of such services in accordance with its
        quality standards.
      </p>
      <p style={{ textAlign: "justify" }}>
        You can choose not to provide us with any or all of the information we
        specify or request, but then you may not be able to register with us or
        to take advantage of some or all of our features. To stop receiving our
        promotional emails, please email info@shilpajivi.com . It may take about
        ten days to process your request. Even if you opt out of getting
        marketing messages, we will still be sending you transactional messages
        through email and SMS about your purchases.
      </p>
      <p style={{ textAlign: "justify" }}>
        It is your obligation to prevent any unauthorized access to your account
        and personal information, by selecting and protecting your password (or
        other sign-on protections) appropriately and limiting access to your
        computer or device by signing off after you have finished accessing your
        account on this Website.
      </p>
      <h5>Third-Party Sharing</h5>
      <p style={{ textAlign: "justify" }}>
        We do not share or otherwise disclose your identifiable or sensitive
        information collected during the provision of services offered by the
        Website for any consideration.
      </p>
      <p style={{ textAlign: "justify" }}>
        We may share non-identifiable and non-sensitive information on
        anonymised bases with our partners and/or affiliates pursuant to
        agreements in writing containing the same or similar safeguards as
        provided under this Policy. BS, however, shall not be responsible for
        any breach of the aforesaid requirement by any such partner and/or
        affiliate.
      </p>
      <p style={{ textAlign: "justify" }}>
        We share your information with delivery and courier companies such as
        DTDC, Delhivery and/or any other third parties for the limited purpose
        of providing delivery services offered by BS, either through this
        Website or our Instagram handle, or any other medium, for ensuring the
        highest standards of quality of service.
      </p>
      <p style={{ textAlign: "justify" }}>
        We share your information with event organizers and other third parties
        responsible for fulfilling the purchase obligation. The event organizers
        and other third parties may use the information we give them as
        described in their privacy policies.
      </p>
      <p style={{ textAlign: "justify" }}>
        We will share information to respond to a court order or subpoena. We
        may also share it if a government agency or investigatory body requests.
        We may share information with our lawyer/legal representative for any
        dispute resolution in connection with the use of this Website or our
        business. Or, we may also share information when we are investigating
        potential fraud.
      </p>
      <p style={{ textAlign: "justify" }}>
        In case we choose to sell our business to another entity, we may share
        information with any successor of ours to all or a part of our business.
      </p>
      <p style={{ textAlign: "justify" }}>
        For any reason not described in this policy, we will not share your
        identifiable or sensitive information, without your prior consent.
      </p>
      <h5>Cookies Policy</h5>
      <p style={{ textAlign: "justify" }}>
        BS uses cookies to improve the experience for visitors to our websites,
        and has published this Cookie Policy. It explains what cookies are and
        how we use them on our websites. If you don’t want to receive cookies
        while browsing our website, you can modify your browser and/or mobile
        device settings so that it notifies you when receiving cookies or you
        can choose to restrict or block cookies.
      </p>
      <p style={{ textAlign: "justify" }}>
        Cookies are tiny files, composed of a string of letters and numbers,
        placed on your computer by web page servers. They enable the website
        owner to distinguish you from other users of the website. Cookies cannot
        be executed as code or used to deliver viruses and they cannot enable us
        to access your hard drive. We cannot read any information on your hard
        drive even if we store cookies there.
      </p>
      <p style={{ textAlign: "justify" }}>
        The data generated through cookies on BS websites can be used for
        various purposes including the following:
        <br></br>
        <br></br>1. Strictly Necessary: These cookies are essential in order to
        enable you to move around the website and use its features.
        <br></br>2. Performance: These cookies collect information about how
        visitors use a website, for instance which pages visitors go to most
        often and if they receive error messages. They are used to improve how a
        website works in future versions.
        <br></br>3. Functionality: These cookies allow the website to remember
        choices you make such as username, language or region you are in and
        provide a more enhanced personal experience.
        <br></br>4. Advertising: These cookies are used to deliver content more
        relevant to you and your interests. We don’t permit any third parties to
        advertise on our website but we cannot control advertisers that are
        allowed by internet service providers to advertise whilst you are
        browsing on the internet. You can learn more about how to manage your
        cookies by visiting the Help function of your browser, the settings of
        your mobile device or you can visit www.aboutcookies.org which provides
        detailed information on managing cookies on popular browsers. Please be
        aware that parts of our websites may not function correctly if you
        disable cookies.
      </p>
      <h5>General Disclaimer</h5>
      <p style={{ textAlign: "justify" }}>
        Any dispute arising between a User and BS, over this Policy, shall be
        governed by the laws of the Republic of India and be subject to BS’s
        terms and conditions of use.
      </p>

      <h5>Contact Us</h5>
      <p style={{ textAlign: "justify" }}>
        If you have any questions about this Policy or any grievance in this
        regard, please contact us at shilpajivi.india@gmail.com
      </p>
    </div>
  );
}
