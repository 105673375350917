import React from "react";

export default function ShippingpolicyComponent() {
  return (
    <div className="container-fluid bg-light px-sm-2  px-lg-5 mt-2">
      <h1>Shipping Policy</h1>
      <p style={{ textAlign: "justify" }}>
        Usually, orders are dispatched within 2-4 working days of the customer
        placing the order.
      </p>
      <p style={{ textAlign: "justify" }}>
        However, unusual circumstances may lead to delays beyond the specified
        period. In case of delay (product not dispatched within the estimated
        time period), you can contact us.
      </p>
      <p style={{ textAlign: "justify" }}>
        To ensure that the order reaches you in a good condition, in the
        shortest span of time, we ship through reputed logistics companies.
      </p>
      <p style={{ textAlign: "justify" }}>
        We currently don’t have a live tracking option available on the website
        but we request you to wait until your parcel is dispatched & you receive
        an email from us, within the above mentioned time frame. If your enquiry
        is urgent, please email us at{" "}
        <strong>shilpajivi.india@gmail.com</strong> and one of our team members
        will be in touch with you at the earliest.
      </p>
    </div>
  );
}
