import React, { useState, useEffect } from "react";

const ImageLoader = (props) => {
  const { width, height, alt, light, className, src, onClick } = props;

  const toBase64 = (str) => {
    return window.btoa(str);
  };

  const shimmer = (w, h) => `
    <svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <linearGradient id="g">
          <stop stop-color="${light ? "#352e44" : "#efedf0"}" offset="20%" />
          <stop stop-color="${light ? "#1f1b2d" : "#d8d7da"}" offset="50%" />
          <stop stop-color="${light ? "#352e44" : "#efedf0"}" offset="70%" />
        </linearGradient>
      </defs>
      <rect width="${w}" height="${h}" fill="${
    light ? "#352e44" : "#efedf0"
  }" />
      <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
      <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
    </svg>`;

  const [imageSrc, setImageSrc] = useState(
    `data:image/svg+xml;base64,${toBase64(shimmer(width, height))}`
  );
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setLoaded(true);
      setImageSrc(src);
    };
    img.onerror = () => {
      console.error(`Failed to load image at ${src}`);
    };
  }, [src]);

  return (
    <img
      className={className}
      src={imageSrc}
      alt={alt}
      style={{ width, height }}
      onClick={onClick}
    />
  );
};

export default ImageLoader;
