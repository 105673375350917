import React from "react";
import ContactUsImage from "../../assets/images/contactus.jpg";
import { Image } from "react-bootstrap";
export default function ContactUsComponent() {
  return (
    <div className="container-fluid bg-light px-sm-2  px-lg-5 mt-2">
      <h1>Contact Us</h1>

      <Image
        src={ContactUsImage}
        alt="Responsive image"
        className="img-custom my-2 d-lg-none "
      />
      <p style={{ textAlign: "justify" }}>
        As your fashion partner, Shilpajivi values your time and commitment.
        When reaching out to us, please ensure you have your registered mobile
        number and order number handy. By providing these details, you enable
        our dedicated support team to swiftly locate your order information and
        assist you more efficiently. We value your time and are committed to
        providing swift, helpful support when you need it most.
      </p>
      <p style={{ textAlign: "justify" }}>
        At Shilpajivi, we understand that your fashion needs may be diverse and
        unique, just like you. So, whether you have a question about our
        collection, need assistance with placing an order, or require help with
        an existing one, feel free to get in touch with us via your preferred
        channel. Our goal is to ensure that your experience with Shilpajivi is
        as distinctive and personalized as the clothes we tailor.
      </p>
      <p style={{ textAlign: "justify" }}>
        We are here to support you in your style journey with Shilpajivi. Quick,
        efficient, and understanding, our support team is just a phone call or
        email away. Connect with us, and let's make your fashion dreams come
        true.
      </p>
      <div className="mb-3">
        <h5>Phone:</h5>
        <a href="tel:+16157663293">+91(709)084-1484</a>
      </div>
      <div className="mb-3">
        <h5>Email:</h5>
        <a href="mailto:shilpajivi.india@gmail.com">
          shilpajivi.india@gmail.com
        </a>
      </div>
      <div className="mb-3">
        <h5>Store Address:</h5>
        <p>
          Shop No:35/5, Beside Richmen Forex, 1st floor, BDA Complex, HSR
          Layout, Bangalore.
        </p>
        <div className="map-responsive">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.665554155417!2d77.62163027507464!3d12.864864887440794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6be7f26a2e4f%3A0x18096faa5c6040ce!2sShilpajivi!5e0!3m2!1sen!2sin!4v1711020022963!5m2!1sen!2sin"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
