import React, { useEffect } from "react";
import NavBar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import { useSelector } from "react-redux";
import PrivacyComponent from "../../components/common/PrivacyComponent";

function PrivacyPage() {
  const navbarHeight = useSelector((state) => state.navbar.height);
  return (
    <>
      <NavBar fixedColor={true} />
      <div style={{ height: navbarHeight }}></div>

      <PrivacyComponent />

      <Footer />
    </>
  );
}

export default PrivacyPage;
