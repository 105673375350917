import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { navbarActions } from "../../store/navbar-slice";

import CollapsedNavBar from "./CollapsedNavBar";
import UncollapsedNavBar from "./UncollapsedNavBar";

const NavBar = ({ fixedColor }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);

      const navbarElement = document.querySelector(".navbar");
      if (navbarElement) {
        dispatch(navbarActions.updateNavbarHeight(navbarElement.offsetHeight));
      }
    };

    window.addEventListener("resize", handleResize);
    // Dispatch the initial navbar height
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  return (
    <>
      {isMobile ? (
        <CollapsedNavBar fixedColor={fixedColor} />
      ) : (
        <UncollapsedNavBar fixedColor={fixedColor} />
      )}
    </>
  );
};

export default NavBar;
