import React, { useState, useEffect } from "react";
import NavBar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import AuthComponent from "../../components/AuthComponent";

export default function LoginPage() {
  const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    const height = document.querySelector(".navbar").offsetHeight;
    setNavbarHeight(height);
  }, []);

  return (
    <>
      <NavBar fixedColor={true} />
      <div style={{ height: navbarHeight }}></div>
      <div
        className="d-flex flex-column bg-light"
        style={{ minHeight: "100vh" }}
      >
        <div className="flex-grow-1">
          <AuthComponent />
        </div>
        <Footer />
      </div>
    </>
  );
}
