import React, { useEffect } from "react";

import NavBar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import { useSelector } from "react-redux";
import TermsComponent from "../../components/common/TermsComponent";

function TermsPage() {
  const navbarHeight = useSelector((state) => state.navbar.height);
  return (
    <>
      <NavBar fixedColor={true} />
      <div style={{ height: navbarHeight }}></div>

      <TermsComponent />

      <Footer />
    </>
  );
}

export default TermsPage;
