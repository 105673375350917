import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  FloatingLabel,
  Container,
  Col,
  Row,
} from "react-bootstrap";
import { auth, db } from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { addressActions } from "../store/address-slice";
import Swal from "sweetalert2";

const UserAddress = ({
  existingAddress,
  showPaymentButton,
  proceedPayment,
}) => {
  const dispatch = useDispatch();

  const [addressLoaded, setAddressLoaded] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [suiteApt, setSuiteApt] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [addressAdded, setAddressAdded] = useState(false);
  useEffect(() => {
    if (existingAddress && existingAddress.addressAdded) {
      setFirstName(existingAddress["firstName"] || "");
      setLastName(existingAddress["lastName"] || "");
      setEmail(existingAddress["email"] || "");
      setAddress(existingAddress["address"] || "");
      setSuiteApt(existingAddress["suiteApt"] || "");
      setCity(existingAddress["city"] || "");
      setState(existingAddress["state"] || "");
      setZipCode(existingAddress["zipCode"] || "");
      setAddressAdded(existingAddress["addressAdded"] || false);
    }
  }, [existingAddress]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const newAddress = {
      firstName,
      lastName,
      email,
      address,
      suiteApt,
      city,
      state,
      zipCode,
      addressAdded: true,
    };
    try {
      Swal.fire({
        title: "Updating address...",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      await setDoc(doc(db, "users", auth.currentUser.phoneNumber), newAddress, {
        merge: true,
      });

      dispatch(addressActions.updateAddress(newAddress));
      setAddressAdded(true);

      Swal.fire({
        icon: "success",
        title: "Address updated successfully",
      });
    } catch (error) {
      console.error("Error updating address: ", error);
      Swal.fire({
        icon: "error",
        title: "Error updating address",
        text: "An error occurred while updating your address. Please try again later.",
      });
    }
  };

  return (
    <Container
      className="my-3 bg-light p-5 "
      style={{
        boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h4>Address</h4>
      <Form onSubmit={handleFormSubmit}>
        <Row className="my-2">
          <Form.Group as={Col} xs={12} md={6} controlId="floatingFirstName">
            <Form.Label>First Name:</Form.Label>
            <Form.Control
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group as={Col} controlId="floatingLastName">
            <Form.Label>Last Name:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </Form.Group>
        </Row>

        <Form.Group controlId="floatingEmail" className="my-2">
          <Form.Label>Email Address:</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="floatingAddress" className="my-2">
          <Form.Label>Address Line 1:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Address Line 1"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="floatingSuiteApt" className="my-2">
          <Form.Label>Address Line 2:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Address Line 2"
            value={suiteApt}
            onChange={(e) => setSuiteApt(e.target.value)}
          />
        </Form.Group>
        <Row className="my-2">
          <Form.Group as={Col} xs={12} md={4} controlId="floatingCity">
            <Form.Label>City:</Form.Label>
            <Form.Control
              type="text"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group as={Col} xs={12} md={4} controlId="floatingState">
            <Form.Label>State:</Form.Label>
            <Form.Control
              type="text"
              placeholder="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group as={Col} xs={12} md={4} controlId="floatingZipCode">
            <Form.Label>Zip Code:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Zip Code"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              required
            />
          </Form.Group>
        </Row>
        <Row className="d-flex mt-3 justify-content-between">
          <Col xs="auto">
            <Button className="mt-3" variant="success" type="submit">
              {addressAdded ? "Update" : "Add"}
            </Button>
          </Col>
          {addressAdded && showPaymentButton && (
            <Col xs="auto">
              <Button
                className="mt-3"
                variant="success"
                onClick={proceedPayment}
              >
                Proceed Payment
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </Container>
  );
};

export default UserAddress;
