//cart-slice.js
import { createSlice } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_MA_MAMA;
const STORAGE_KEY = process.env.REACT_APP_BA_RAKA;
const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
    totalQuantity: 0,
    changed: false,
    totalCartValue: 0,
    cartUploaded: false,
  },
  reducers: {
    replaceCart(state, action) {
      if (action.payload) {
        state.totalQuantity = action.payload.totalQuantity;
        state.items = action.payload.items;
        state.totalCartValue = cartTotalAmount(state.items);
      } else {
        state.items = [];
        state.totalQuantity = 0;
        state.totalCartValue = 0;
        state.cartUploaded = false;
      }
    },
    cartUpload(state, action) {
      state.cartUploaded = action.payload;
    },

    addItemToCart(state, action) {
      const newItem = action.payload;
      state.totalQuantity++;
      state.changed = true;
      const existingItem = state.items.find(
        (item) => item.uniqueId === newItem.uniqueId
      );
      if (!existingItem) {
        state.items.push({
          uniqueId: newItem.uniqueId,
          productId: newItem.productId,
          size: newItem.size,
          price: newItem.price,
          quantity: 1,
          instructions: newItem.instructions,
          totalPrice: newItem.price,
          category: newItem.category,
          image: newItem.image,
          name: newItem.name,
        });
      } else {
        existingItem.quantity++;
        existingItem.totalPrice =
          Number(existingItem.totalPrice) + Number(newItem.price);
      }
      state.totalCartValue = cartTotalAmount(state.items);
    },
    removeItemFromCart(state, action) {
      const uniqueId = action.payload;
      const existingItem = state.items.find(
        (item) => item.uniqueId === uniqueId
      );

      state.totalQuantity--;
      state.changed = true;
      if (existingItem.quantity === 1) {
        state.items = state.items.filter((item) => item.uniqueId !== uniqueId);
      } else {
        existingItem.quantity--;
        existingItem.totalPrice =
          Number(existingItem.totalPrice) - Number(existingItem.price);
      }
      state.totalCartValue = cartTotalAmount(state.items);
    },
    deleteItem(state, action) {
      const uniqueId = action.payload;
      const itemToBeDeleted = state.items.find(
        (item) => item.uniqueId === uniqueId
      );
      if (itemToBeDeleted) {
        state.totalQuantity -= itemToBeDeleted.quantity;
        state.items = state.items.filter((item) => item.uniqueId !== uniqueId);
        state.totalCartValue = cartTotalAmount(state.items);
      }
    },
  },
});
// function cartTotalAmount(items) {
//   return items.reduce(function (total, cartItem) {
//     return total + cartItem.price * cartItem.quantity;
//   }, 0.0);
// }
const cartTotalAmount = (items) => {
  // Use the Array.prototype.reduce method to accumulate a single value from the array of items.
  // For each item in the array, calculate the subtotal for the current cart item (price * quantity)
  // Add this subtotal to the accumulated total and return the new total.
  // This new total then becomes the accumulated value for the next invocation of the callback function.
  return items.reduce(
    (total, cartItem) => total + cartItem.price * cartItem.quantity,
    0.0
  );
};

// Middleware for handling local storage
export const localStorageMiddleware = (store) => (next) => (action) => {
  let result = next(action);

  // Check if the action affects the cart state
  if (action.type.startsWith("cart/")) {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(store.getState().cart),
      SECRET_KEY
    ).toString();
    localStorage.setItem(STORAGE_KEY, encryptedData);
  }

  return result;
};

// Load the previous state from local storage
export const loadFromLocalStorage = () => {
  const encryptedData = localStorage.getItem(STORAGE_KEY);
  if (encryptedData !== null) {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }
  return {
    items: [],
    totalQuantity: 0,
    changed: false,
    totalCartValue: 0,
    cartUploaded: false,
  };
};

// Action to load initial state from local storage
export const loadInitialState = (store) => {
  const initialState = loadFromLocalStorage();
  store.dispatch(cartActions.replaceCart(initialState));
};

export const cartActions = cartSlice.actions;
export default cartSlice;
