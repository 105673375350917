import { createSlice } from "@reduxjs/toolkit";
import { db, auth } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

const addressSlice = createSlice({
  name: "address",
  initialState: { address: {} },
  reducers: {
    updateAddress(state, action) {
      state.address = action.payload;
    },
    clearAddress(state) {
      state.address = {};
    },
  },
});
export const fetchAddress = () => {
  return async (dispatch) => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        let currentUser = user.phoneNumber;

        try {
          if (currentUser) {
            const docRef = doc(db, "users", currentUser);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              // Dispatch the fetched address to the Redux store
              dispatch(addressActions.updateAddress(docSnap.data()));
            }
          }
        } catch (error) {
          console.error("Error fetching address: ", error);
        }
      } else {
        // User is signed out
        // Handle it according to your app's behavior
        // console.log("No user is signed in.");
      }
    });
  };
};

export const addressActions = addressSlice.actions;
export default addressSlice;
